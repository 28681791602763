import React from 'react';
import { useHistory } from 'react-router-dom';

import { validateNoNumbers } from './../../validators/validateNoNumbers';
import { handleErrorsInputFile } from './../../validators/handleErrorsInputFile';
import Icon from '../../components/Icon';

import $ from 'jquery';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';

import Swal from 'sweetalert2'
import config from '../../config';
const URL_API = config.urlApi;

export default function AddPermisionario(){

    const history = useHistory()

    $(document).ready(function(){
        $('.selectpicker').selectpicker();
    });

    React.useEffect(() => {
        getDataForm();
    }, []);

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        if(file){
            document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
            $('#'+nombreInput+'Preview').removeClass("hidden")
        } else {
            document.getElementById(nombreInput+'Preview').src = ""
            $('#'+nombreInput+'Preview').addClass("hidden")
        }
    }

    function onChangeImageInput(e){
        const file = e.target.files[0];
        if(file){
            document.getElementById("perfil__imagen").src = URL.createObjectURL(file);
        } else {
            document.getElementById("perfil__imagen").src = "https://via.placeholder.com/500/FF7925/fff?text=Foto perfil";
        }
    }

    const btnAgregarClave = async() => {

        const steps = ['1', '2']
        const preguntas = ['Escribe la nueva clave', '¿Cuándo se emitió o emitirá la clave?']
        const swalQueueStep = Swal.mixin({
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Atrás',
            progressSteps: steps,
            buttonsStyling: false,
            input: 'text',
            inputAttributes: {
                required: true
            },
            reverseButtons: true,
            validationMessage: 'Este campo es requerido',
        })

        const values = []
        let currentStep

        for (currentStep = 0; currentStep < steps.length;) {
            const result = await swalQueueStep.fire({
                title: preguntas[currentStep],
                text: (currentStep === 0) ? '' : 'Asegúrate de escribir bien el formato de la fecha aaaa/mm/dd',
                inputValue: values[currentStep],
                showCancelButton: currentStep > 0,
                currentProgressStep: currentStep,
                customClass: {
                    confirmButton: 'btnPrimario',
                    cancelButton: 'btnPrimario btnPrimario-colorSecundario',
                    input: (currentStep === 1) ? 'fechaEmision' : ''
                },
                inputPlaceholder: (currentStep === 1) ? 'aaaa/mm/dd' : '',
                showLoaderOnConfirm: true,
                preConfirm: (clave) => {
                    if(currentStep === 0){
                        // VERIFICAR SI EXISTE LA CLAVE
                        return $.ajax({
                            data:{clave:clave},
                            url: URL_API+'controlador/existClave.controlador.php',
                            type: 'POST',
                        }).then((response)=>{
                            let _respuesta = JSON.parse(response)
                            if(_respuesta.response === 'error'){
                                Swal.showValidationMessage('La clave ya existe')
                            }
                            return clave;
                        },()=>{
                            Swal.showValidationMessage('Error del sistema, contacte al desarrollador')
                        })
                    }
                    
                },
            })

            if (result.value) {
                values[currentStep] = result.value
                currentStep++
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                currentStep--
            } else {
                break
            }
        }

        if (currentStep === steps.length) {
            let nuevaClave = values[0]
            let fechaEmision = values[1]
            const user = JSON.parse(localStorage.getItem("user"))
            const id = user.id_usuario

            $.ajax({
                data:{clave:nuevaClave, fechaEmision:fechaEmision, id: id},
                url: URL_API+'controlador/addClave.controlador.php',
                type: 'POST',
                success: function(response){
                    let _respuesta = JSON.parse(response)
                    if(_respuesta.response === 'success'){
                        setClaves([...claves, {clave:nuevaClave, fechaEmision:fechaEmision}])
                        setPermisionarioClave(nuevaClave)
                        $('.selectpicker').selectpicker('refresh')
                        Swal.fire(
                            'Clave agregada',
                            '',
                            'success'
                        )
                    } else {
                        Swal.fire(
                            'Ocurrió un error',
                            'Vuelve a intentarlo',
                            'error'
                        )
                    }

                }
            })
        }
    }

    function getDataForm(){
        $.ajax({
            url: URL_API+'controlador/getDataAltaPermisionario.controlador.php',
            success: function(response){
                let _respuesta = JSON.parse(response)
                console.log(_respuesta)
                if(!(_respuesta.response === 'error')){
                    setClaves((!(_respuesta.claves) ? [] : _respuesta.claves))
                    setTiposPermisionarios(_respuesta.tipos_permisionarios)
                    setResponsablesPermisionarios((!(_respuesta.responsables) ? [] : _respuesta.responsables))
                    setEstadosCiviles(_respuesta.estados_civiles)
                    setEstadosPermisionarios(_respuesta.estados)
                    setGenerosPermisionarios(_respuesta.generos)
                    setReligiones(_respuesta.creencia_religiosa)
                    $('.selectpicker').selectpicker('refresh')
                }
            }

        })
    }

    function getMunicipios(estado){
        $.ajax({
            data:{estado:estado},
            url: URL_API+'controlador/getMunicipios.controlador.php',
            type:"POST",
            success: function(response){
                let _respuesta = JSON.parse(response)
                if(!(_respuesta.response === 'error')){
                    setMunicipiosPermisionarios(_respuesta.municipios)
                    $("#permisionarioMunicipio").attr("disabled",false)
                    $('.selectpicker').selectpicker('refresh')
                }
            }
        })
    }

    const [claves, setClaves] = React.useState([]);
    const [tiposPermisionarios, setTiposPermisionarios] = React.useState([]);
    const [responsablesPermisionarios, setResponsablesPermisionarios] = React.useState([]);
    const [estadosCiviles, setEstadosCiviles] = React.useState([]);
    const [estadosPermisionarios, setEstadosPermisionarios] = React.useState([]);
    const [municipiosPermisionarios, setMunicipiosPermisionarios] = React.useState([]);
    const [generosPermisionarios, setGenerosPermisionarios] = React.useState([]);
    const [religiones, setReligiones] = React.useState([]);


    const [permisionarioNombre, setPermisionarioNombre] = React.useState('');
    const [permisionarioApellidoPaterno, setPermisionarioApellidoPaterno] = React.useState('');
    const [permisionarioApellidoMaterno, setPermisionarioApellidoMaterno] = React.useState('');
    const [permisionarioFechaNacimiento, setPermisionarioFechaNacimiento] = React.useState('');
    const [permisionarioGenero, setPermisionarioGenero] = React.useState('1');
    const [permisionarioCelular, setPermisionarioCelular] = React.useState('');
    const [permisionarioLocal, setPermisionarioLocal] = React.useState('');
    const [permisionarioCorreo, setPermisionarioCorreo] = React.useState('');
    const [permisionarioCurp, setPermisionarioCurp] = React.useState('');
    
    const [permisionarioNombreDeLaCalle, setPermisionarioNombreDeLaCalle] = React.useState('');
    const [permisionarioNumeroExterior, setPermisionarioNumeroExterior] = React.useState('');
    const [permisionarioNumeroInterior, setPermisionarioNumeroInterior] = React.useState('');
    const [permisionarioReferencias, setPermisionarioReferencias] = React.useState('');
    const [permisionarioColonia, setPermisionarioColonia] = React.useState('');
    const [permisionarioEstado, setPermisionarioEstado] = React.useState('');
    const [permisionarioMunicipio, setPermisionarioMunicipio] = React.useState('1');
    const [permisionarioCodigoPostal, setPermisionarioCodigoPostal] = React.useState('');

    const [permisionarioTipoDeIdentificacion, setPermisionarioTipoDeIdentificacion] = React.useState('');
    const [permisionarioClave, setPermisionarioClave] = React.useState('');
    const [permisionarioRFC, setPermisionarioRFC] = React.useState('');
    const [permisionarioNumeroSeguridadSocial, setPermisionarioNumeroSeguridadSocial] = React.useState('');
    const [permisionarioResponsable, setPermisionarioResponsable] = React.useState('');
    const [permisionarioFechaIngreso, setPermisionarioFechaIngreso] = React.useState('');
    const [permisionarioEstadoCivil, setPermisionarioEstadoCivil] = React.useState('1');
    const [idCreenciaReligiosa, setIdCreenciaReligiosa] = React.useState('');
    const [permisionarioEsposa, setPermisionarioEsposa] = React.useState('');
    const [permisionarioContactoEmergencia, setPermisionarioContactoEmergencia] = React.useState('');
    const [permisionarioComentarios, setPermisionarioComentarios] = React.useState('');

    function onSubmitPermisionario(e){
        e.preventDefault();

        var formData = new FormData();
        formData.append('permisionarioNombre', permisionarioNombre);
        formData.append('permisionarioApellidoPaterno', permisionarioApellidoPaterno);
        formData.append('permisionarioApellidoMaterno', permisionarioApellidoMaterno);
        formData.append('permisionarioFechaNacimiento', permisionarioFechaNacimiento);
        formData.append('permisionarioGenero', permisionarioGenero);
        formData.append('permisionarioCelular', permisionarioCelular);
        formData.append('permisionarioLocal', permisionarioLocal);
        formData.append('permisionarioCorreo', permisionarioCorreo);
        formData.append('permisionarioCurp', permisionarioCurp);

        formData.append('permisionarioNombreDeLaCalle', permisionarioNombreDeLaCalle);
        formData.append('permisionarioNumeroExterior', permisionarioNumeroExterior);
        formData.append('permisionarioNumeroInterior', permisionarioNumeroInterior);
        formData.append('permisionarioReferencias', permisionarioReferencias);
        formData.append('permisionarioColonia', permisionarioColonia);
        formData.append('permisionarioEstado', permisionarioEstado);
        formData.append('permisionarioMunicipio', permisionarioMunicipio);
        formData.append('permisionarioCodigoPostal', permisionarioCodigoPostal);

        formData.append('permisionarioTipoDeIdentificacion', permisionarioTipoDeIdentificacion);
        formData.append('permisionarioClave', permisionarioClave);
        formData.append('permisionarioRFC', permisionarioRFC);
        formData.append('permisionarioNumeroSeguridadSocial', permisionarioNumeroSeguridadSocial);
        formData.append('permisionarioResponsable', permisionarioResponsable);
        formData.append('permisionarioFechaIngreso', permisionarioFechaIngreso);
        formData.append('permisionarioEstadoCivil', permisionarioEstadoCivil);
        formData.append('permisionarioIdCreenciaReligiosa', idCreenciaReligiosa);
        formData.append('permisionarioEsposa', permisionarioEsposa);
        formData.append('permisionarioContactoEmergencia', permisionarioContactoEmergencia);
        formData.append('permisionarioComentarios', permisionarioComentarios);
        
        // Agregamos los nuevos campos al formData
        let tiene_alergias = document.getElementById("tiene_alergias").value;
        let tiene_enfermedad_cronica = document.getElementById("tiene_enfermedad_cronica").value;
        let tiene_familiar_discapacidad = document.getElementById("tiene_familiar_discapacidad").value;
        let tiene_hijos = document.getElementById("tiene_hijos").value;
        formData.append('tiene_alergias', tiene_alergias);
        formData.append('tiene_enfermedad_cronica', tiene_enfermedad_cronica);
        formData.append('tiene_familiar_discapacidad', tiene_familiar_discapacidad);
        formData.append('tiene_hijos', tiene_hijos);

        let fotoPerfil = document.getElementById('fotoPerfil').files;
        let actaDeNacimiento = document.getElementById("actaDeNacimiento").files;
        let rfcDocumento = document.getElementById("rfcDocumento").files;
        let comprobanteDomicilio = document.getElementById("comprobanteDomicilio").files;
        let INEVistaFrontal = document.getElementById("INEVistaFrontal").files;
        let INEVistaTrasera = document.getElementById("INEVistaTrasera").files;
        let boletaPredial = document.getElementById("boletaPredial").files;
        let cartaReferenciaComercial = document.getElementById("cartaReferenciaComercial").files;
        let cartasReferenciaPersonal = document.getElementById("cartasReferenciaPersonal").files;
        let actaMatrimonio = document.getElementById("actaMatrimonio").files;
        let estudioSocioeconomico = document.getElementById("estudioSocioeconomico").files;
        let antecedentesPenales = document.getElementById("antecedentesPenales").files;
        let solicitudDeIngreso = document.getElementById("solicitudDeIngreso").files;
        
        if( handleErrorsInputFile(fotoPerfil, "Foto de perfil") || 
            handleErrorsInputFile(actaDeNacimiento, "Acta de nacimiento") || 
            handleErrorsInputFile(rfcDocumento, "RFC") || 
            handleErrorsInputFile(comprobanteDomicilio, "Comprobante de domicilio") ||
            handleErrorsInputFile(INEVistaFrontal, "INE Vista frontal") ||
            handleErrorsInputFile(INEVistaTrasera, "INE Vista trasera") ||
            handleErrorsInputFile(boletaPredial, "Boleta de predial") ){
            return;
        }

        formData.append('fotoPerfil', fotoPerfil.item(0))
        formData.append('actaDeNacimiento', actaDeNacimiento.item(0));
        formData.append('rfcDocumento', rfcDocumento.item(0));
        formData.append('comprobanteDomicilio', comprobanteDomicilio.item(0));
        formData.append('INEVistaFrontal', INEVistaFrontal.item(0));
        formData.append('INEVistaTrasera', INEVistaTrasera.item(0));
        formData.append('boletaPredial', boletaPredial.item(0));
        formData.append('cartaReferenciaComercial', cartaReferenciaComercial.item(0));
        formData.append('cartasReferenciaPersonal', cartasReferenciaPersonal.item(0));
        formData.append('actaMatrimonio', actaMatrimonio.item(0));
        formData.append('estudioSocioeconomico', estudioSocioeconomico.item(0));
        formData.append('antecedentesPenales', antecedentesPenales.item(0));
        formData.append('solicitudDeIngreso', solicitudDeIngreso.item(0));

        const user = JSON.parse(localStorage.getItem("user"))
        const id = user.id_usuario
        formData.append('id',id);

        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        $.ajax({
            xhr: function() {

                var xhr = new window.XMLHttpRequest();

                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        Swal.fire({
                            title: 'Subiendo permisionario...',
                            html: '<b></b>% de 100%',
                            timerProgressBar: true,
                            showConfirmButton: false,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                var percentComplete = evt.loaded / evt.total;
                                percentComplete = parseInt(percentComplete * 100);
                                b.textContent = `${percentComplete}%`
                                console.log(percentComplete)
                        
                                if (percentComplete === 100) {
                                    // Swal.close()
                                    Swal.hideLoading();
                                    Swal.fire({
                                        title:"Permisionario agregado",
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500
                                    }).then(function(){
                                        history.push('/permisionarios');
                                    })
                                }
                            },
                        })
                    }
                }, false);
            
                return xhr;
            },
            url:URL_API+'controlador/permisionario.controlador.php',
            data: formData,
            type: "POST",
            contentType: false,
            processData: false,
            success:function(respuesta){
                console.log(respuesta);
                // let _respuesta = JSON.parse(respuesta);
                // if(_respuesta.response === 'success'){
                    
                // } else {
                //     Swal.fire(
                //         'Ocurrió un error',
                //         'Vuelve a intentarlo',
                //         'error'
                //     )
                // }
            }
        })

    }

    function onChangePermisionarioEstado(value){
        setPermisionarioEstado(value)
        getMunicipios(value)
    }

    return (
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar un permisionario</h2>

            <p>
                Para agregar un permisionario debes completar el siguiente formulario
            </p>

            <div className="perfil__fotoPerfil">
                <div className="perfil__miniatura">
                    <img id="perfil__imagen" className="perfil__imagen img-fluid" src="https://via.placeholder.com/500/FF7925/fff?text=Foto perfil" />
                </div>
                <input type="file" id="fotoPerfil" className="hidden" accept="image/png, image/jpeg" onChange={ (e) => onChangeImageInput(e) } name="fotoPerfil" required />
                <div>
                    <label htmlFor="fotoPerfil" className="perfil__cambiarFoto">
                        <h3>
                            Foto del permisionario
                        </h3>
                        <p>
                            Da clic aquí para cargar una foto de perfil al permisionario
                        </p>
                    </label>
                </div>
            </div>

            <form className="formulario" onSubmit={ (e) => onSubmitPermisionario(e) }>

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}
            
                <div className="datosPersonales my-5">
                    <h3>
                        Datos personales
                    </h3>

                    <div className="datosPersonales__formulario row">
                        <div className="col-4">
                            <label htmlFor="permisionarioNombre">
                                Nombre(s):
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="permisionarioNombre"
                                value={ permisionarioNombre }
                                onChange = { (e) => setPermisionarioNombre(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioApellidoPaterno">
                                Apellido paterno:
                            </label>
                            <input 
                                type="text" 
                                className="input" 
                                id="permisionarioApellidoPaterno"
                                value={ permisionarioApellidoPaterno }
                                onChange = { (e) => setPermisionarioApellidoPaterno(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioApellidoMaterno">
                                Apellido materno:
                            </label>
                            <input 
                                type="text"
                                className="input" 
                                id="permisionarioApellidoMaterno"
                                value={ permisionarioApellidoMaterno }
                                onChange = { (e) => setPermisionarioApellidoMaterno(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioFechaNacimiento">
                                Fecha nacimiento:
                            </label>
                            <input 
                                type="date"
                                className="input"
                                id="permisionarioFechaNacimiento"
                                value={ permisionarioFechaNacimiento }
                                onChange = { (e) => setPermisionarioFechaNacimiento(e.target.value) }
                                required
                                />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioGenero">
                                Género:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioGenero"
                                value={ permisionarioGenero }
                                onChange = { (e) => setPermisionarioGenero(e.target.value) }
                                required
                            >
                                {
                                    generosPermisionarios && 
                                    generosPermisionarios.map( genero => (
                                        <option key={ genero.id_genero } value={ genero.id_genero }>
                                            { genero.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioCelular">
                                Número de celular:
                            </label>
                            <input 
                                type="tel" 
                                placeholder="#########" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="permisionarioCelular"
                                value={ permisionarioCelular }
                                onChange = { (e) => setPermisionarioCelular(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioLocal">
                                Número local:
                            </label>
                            <input 
                                type="tel" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="permisionarioLocal"
                                value={ permisionarioLocal }
                                onChange = { (e) => setPermisionarioLocal(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioCorreo">
                                Correo electrónico:
                            </label>
                            <input 
                                type="email" 
                                className="input"
                                id="permisionarioCorreo"
                                value={ permisionarioCorreo }
                                onChange = { (e) => setPermisionarioCorreo(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioCurp">
                                CURP:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="permisionarioCurp"
                                maxLength="20"
                                value={ permisionarioCurp }
                                onChange = { (e) => setPermisionarioCurp(e.target.value) }
                                required
                            />
                        </div>
                    </div>

                </div>
               
               
                {/* ********************************* */}
                {/* BLOQUE DE LOS DATOS DEL DOMICILIO */}
                {/* ********************************* */}
            
                <div className="datosDomicilio my-5">
                    <h3>
                        Datos de domicilio
                    </h3>

                    <div className="datosDomicilio__formulario row">
                        <div className="col-4">
                            <label htmlFor="permisionarioNombreDeLaCalle">
                                Calle:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="permisionarioNombreDeLaCalle"
                                value={ permisionarioNombreDeLaCalle }
                                onChange = { (e) => setPermisionarioNombreDeLaCalle(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="permisionarioNumeroExterior">
                                No. exterior:
                            </label>
                            <input 
                                type="num" 
                                placeholder="###" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                id="permisionarioNumeroExterior"
                                value={ permisionarioNumeroExterior }
                                onChange = { (e) => setPermisionarioNumeroExterior(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="permisionarioNumeroInterior">
                                No. interior:
                            </label>
                            <input 
                                type="num" 
                                placeholder="opcional" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                id="permisionarioNumeroInterior"
                                value={ permisionarioNumeroInterior }
                                onChange = { (e) => setPermisionarioNumeroInterior(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioReferencias">
                                Referencias:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="permisionarioReferencias"
                                value={ permisionarioReferencias }
                                onChange = { (e) => setPermisionarioReferencias(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioColonia">
                                Colonia:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="permisionarioColonia"
                                value={ permisionarioColonia }
                                onChange = { (e) => setPermisionarioColonia(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="permisionarioEstado">
                                Estado:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioEstado"
                                value={ permisionarioEstado }
                                onChange = { (e) => onChangePermisionarioEstado(e.target.value) }
                                required
                                data-live-search="true"
                            >   
                                <option value="">Elige un estado</option>
                                {
                                    estadosPermisionarios.map( (estado) => (
                                        <option key={ estado.id_estado } value={ estado.id_estado } >
                                            { estado.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-2">
                            <label htmlFor="permisionarioMunicipio">
                                Municipio:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioMunicipio"
                                value={ permisionarioMunicipio }
                                onChange={ (e) => setPermisionarioMunicipio(e.target.value) }
                                required
                                data-live-search="true"
                                disabled
                            >
                                <option>
                                    Elige un municipio
                                </option>
                                {
                                    municipiosPermisionarios.map( (municipio) => (
                                        <option key={ municipio.id_municipio } value={ municipio.id_municipio } >
                                            { municipio.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioCodigoPostal">
                                Código postal:
                            </label>
                            <input 
                                type="num" 
                                placeholder="#####" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength="10"
                                id="permisionarioCodigoPostal"
                                value={ permisionarioCodigoPostal }
                                onChange={ (e) => setPermisionarioCodigoPostal(e.target.value) }
                                required
                            />
                        </div>
                    </div>

                </div>
               
               
                {/* ************************************* */}
                {/* BLOQUE DE LOS DATOS DE IDENTIFICACIÓN */}
                {/* ************************************* */}
            
                <div className="datosIdentificacion my-5">
                    <h3>
                        Datos de identificación
                    </h3>

                    <div className="datosIdentificacion__formulario row">
                        <div className="col-4">
                            <label htmlFor="permisionarioTipoDeIdentificacion">
                                Tipo permisionario:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioTipoDeIdentificacion"
                                value={ permisionarioTipoDeIdentificacion }
                                onChange={ (e) => setPermisionarioTipoDeIdentificacion(e.target.value) }
                                required
                            >
                                <option>
                                    ¿Qué tipo de permisionario es?
                                </option>
                                {
                                    tiposPermisionarios &&
                                    tiposPermisionarios.map( (tipo, index) => (
                                        <option key={ tipo.id_tipo_permisionario } value={ tipo.id_tipo_permisionario }>
                                            { tipo.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioClave">
                                Clave:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioClave"   
                                value={ permisionarioClave }
                                onChange={ (e) => setPermisionarioClave(e.target.value) } 
                                data-live-search="true"
                                required
                            >
                                <option>
                                    Seleccionar...
                                </option>
                                {
                                    claves.map( (clave) => (
                                        <option key={ clave.clave } value={ clave.clave }>
                                            { clave.clave }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-4 d-flex align-items-center">
                            <button type="button" id="btnAgregarClavePermisionario" className="btnPrimario btnPrimario-sm" onClick={ btnAgregarClave }>
                                <p>Agregar clave</p>
                                <i className="fas fa-key mr-2"></i>
                            </button>
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioRFC">
                                RFC:
                            </label>
                            <input 
                                type="text"
                                placeholder="XXXX#####XXX" 
                                className="input"
                                id="permisionarioRFC"
                                value={ permisionarioRFC }
                                onChange={ (e) => setPermisionarioRFC(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioNumeroSeguridadSocial">
                                # Seg. social:
                            </label>
                            <input 
                                type="num" 
                                placeholder="Opcional" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                id="permisionarioNumeroSeguridadSocial"
                                value={ permisionarioNumeroSeguridadSocial }
                                onChange={ (e) => setPermisionarioNumeroSeguridadSocial(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioResponsable">
                                Responsable:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioResponsable"
                                value={ permisionarioResponsable }
                                onChange={ (e) => setPermisionarioResponsable(e.target.value) }
                                data-live-search="true"
                            >
                                <option value="">
                                    Opcional
                                </option>
                                {
                                    responsablesPermisionarios.map( (responsable, index) => (
                                        <option key={ responsable.id_permisionario } value={ responsable.id_permisionario }>
                                            { responsable.nombre+' '+responsable.apellido_paterno+' '+responsable.apellido_materno }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioFechaIngreso">
                                Fecha ingreso:
                            </label>
                            <input 
                                type="date"
                                className="input" 
                                id="permisionarioFechaIngreso"
                                value={ permisionarioFechaIngreso }
                                onChange={ (e) => setPermisionarioFechaIngreso(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisionarioEstadoCivil">
                                Estado civil:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisionarioEstadoCivil"  
                                value={ permisionarioEstadoCivil }
                                onChange={ (e) => setPermisionarioEstadoCivil(e.target.value) }
                                required
                            >
                                {
                                    estadosCiviles &&
                                    estadosCiviles.map( (estadoCivil) => (
                                        <option key={ estadoCivil.id_estado_civil } value={ estadoCivil.id_estado_civil }>
                                            { estadoCivil.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="id_creencia_religiosa">
                                Religión:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="id_creencia_religiosa"  
                                value={ idCreenciaReligiosa }
                                onChange={ (e) => setIdCreenciaReligiosa(e.target.value) }
                                required
                            >
                                <option value="">
                                    Opcional
                                </option>
                                {
                                    religiones &&
                                    religiones.map( (religion) => (
                                        <option key={ religion.id_creencia_religiosa } value={ religion.id_creencia_religiosa }>
                                            { religion.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>

                        <div className="col-6">
                            <label htmlFor="permisionarioEsposa">
                                Sí tiene esposa, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="permisionarioEsposa"
                                value={ permisionarioEsposa }
                                onChange={ (e) => setPermisionarioEsposa(e.target.value) }
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="permisionarioContactoEmergencia">
                                Contacto de emergencia:
                            </label>
                            <input 
                                type="num"
                                onKeyPress={ validateNoNumbers }
                                placeholder="Opcional" 
                                className="input"
                                id="permisionarioContactoEmergencia"
                                value={ permisionarioContactoEmergencia }
                                onChange={ (e) => setPermisionarioContactoEmergencia(e.target.value) }
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_alergias">
                                ¿Tiene alergias?, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_alergias"
                                name="tiene_alergias"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_enfermedad_cronica">
                                ¿Tiene alguna enfermedad crónica?, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_enfermedad_cronica"
                                name="tiene_enfermedad_cronica"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_familiar_discapacidad">
                                ¿Tiene familiar con discapacidad física o motriz?, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_familiar_discapacidad"
                                name="tiene_familiar_discapacidad"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_hijos">
                                ¿Tiene hijos?, escribe cuántos:
                            </label>
                            <input 
                                type="num"
                                placeholder="Opcional" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength={2}
                                id="tiene_hijos"
                                name="tiene_hijos"
                            />
                        </div>
                        <div className="col-12">
                            <label htmlFor="permisionarioComentarios">
                                Anotaciones:
                            </label>
                            <textarea 
                                rows={5}
                                cols={5}
                                type="text"
                                placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre el permisionario" 
                                className="input textarea"
                                id="permisionarioComentarios"
                                value={ permisionarioComentarios }
                                onChange={ (e) => setPermisionarioComentarios(e.target.value) }
                            />
                        </div>
                    </div>

                </div>

                {/* ********************************* */}
                {/* BLOQUE DE LA SUBIDA DE DOCUMENTOS */}
                {/* ********************************* */}

                <div className="subidaDocumentos my-5">
                    <h3>
                        Documentos personales
                    </h3>
                    
                    <p>
                        Tienes que subir cada documento por separado, da clic en el botón del pdf que quieres subir y selecciona el archivo.
                    </p>

                    <div className="subidaDocumentos__formulario row mt-3">
                        <div className="col-4">
                            <input type="file" id="actaDeNacimiento" className="hidden" accept="application/pdf,application/vnd.ms-excel" name="actaDeNacimiento" onChange={ (e) => onChangeFileInput(e, "actaDeNacimiento") } />
                            <label htmlFor="actaDeNacimiento">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir acta de nacimiento
                                </a>
                            </label>
                            <embed
                                id="actaDeNacimientoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>
                        
                        <div className="col-4">
                            <input type="file" id="rfcDocumento" name="rfcDocumento" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "rfcDocumento") } />
                            <label htmlFor="rfcDocumento">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir documento de RFC
                                </a>
                            </label>
                            <embed
                                id="rfcDocumentoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="comprobanteDomicilio" name="comprobanteDomicilio" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "comprobanteDomicilio") } />
                            <label htmlFor="comprobanteDomicilio">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir comprobante de domicilio
                                </a>
                            </label>
                            <embed
                                id="comprobanteDomicilioPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="INEVistaFrontal" name="INEVistaFrontal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "INEVistaFrontal") } />
                            <label htmlFor="INEVistaFrontal">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    INE (vista frontal)
                                </a>
                            </label>
                            <embed
                                id="INEVistaFrontalPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="INEVistaTrasera" name="INEVistaTrasera" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "INEVistaTrasera") } />
                            <label htmlFor="INEVistaTrasera">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    INE (vista trasera)
                                </a>
                            </label>
                            <embed
                                id="INEVistaTraseraPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="boletaPredial" name="boletaPredial" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "boletaPredial") } />
                            <label htmlFor="boletaPredial">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Boleta predial
                                </a>
                            </label>
                            <embed
                                id="boletaPredialPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="cartaReferenciaComercial" name="cartaReferenciaComercial" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "cartaReferenciaComercial") } />
                            <label htmlFor="cartaReferenciaComercial">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Carta de referencia comercial
                                </a>
                            </label>
                            <embed
                                id="cartaReferenciaComercialPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="cartasReferenciaPersonal" name="cartasReferenciaPersonal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "cartasReferenciaPersonal") } />
                            <label htmlFor="cartasReferenciaPersonal">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Cartas de referencia personal
                                </a>
                            </label>
                            <embed
                                id="cartasReferenciaPersonalPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="actaMatrimonio" name="actaMatrimonio" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "actaMatrimonio") } />
                            <label htmlFor="actaMatrimonio">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Acta de matrimonio
                                </a>
                            </label>
                            <embed
                                id="actaMatrimonioPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="estudioSocioeconomico" name="estudioSocioeconomico" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "estudioSocioeconomico") } />
                            <label htmlFor="estudioSocioeconomico">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Estudio socioeconómico
                                </a>
                            </label>
                            <embed
                                id="estudioSocioeconomicoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="antecedentesPenales" name="antecedentesPenales" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "antecedentesPenales") } />
                            <label htmlFor="antecedentesPenales">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Antecendentes penales
                                </a>
                            </label>
                            <embed
                                id="antecedentesPenalesPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="solicitudDeIngreso" name="solicitudDeIngreso" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "solicitudDeIngreso") } />
                            <label htmlFor="solicitudDeIngreso">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Solicitud de ingreso
                                </a>
                            </label>
                            <embed
                                id="solicitudDeIngresoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                    </div>

                </div>

                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-user mr-3" />
                        Agregar permisionario
                    </button>
                </div>


            </form>


        </section>
    );
}