import Swal from 'sweetalert2'
import React from "react";
import deleteAsignacionVehiculoConcesionEconomico from '../../services/historial_vehiculo_concesion_economico/deleteAsignacionVehiculoConcesionEconomico';
import updateAsignacionVehiculoConcesionEconomico from '../../services/historial_vehiculo_concesion_economico/updateAsignacionVehiculoConcesionEconomico';
export default function TableElementHistorialConcesionesEconomicos({ element, permisosUsuario, getReloadElements }) {

    let { 
        id_historial_vehiculo_concesion_economico,
        id_economico, 
        numero, 
        date, 
        clave_concesion, 
        prefijo_clave, 
        fecha_asignacion,
        nombre_derrotero,
        clave_derrotero,
        activo } = element;

    const [estadoEstatus, setEstadoEstatus] = React.useState(activo);

    const onClickEstatus = (activo) => {
        let msg = 'Seguro que quieres desactivar el funcionamiento del vehículo?';
        Swal.fire({
            title: msg,
            text: 'Ya no se podrán recibir recaudaciones ni reportar siniestros',
            showCancelButton: true,
            confirmButtonText: 'Si, quiero desactivarlo',
            showLoaderOnConfirm: true,
            cancelButtonText: 'Cancelar',
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_historial_vehiculo_concesion_economico', id_historial_vehiculo_concesion_economico)
                values.append('activo', activo ? '1' : '0')
                let objectValues = Object.fromEntries(values);
                console.log(objectValues)
                updateAsignacionVehiculoConcesionEconomico({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }

                    if (_respuesta.response === "error") {
                        setEstadoEstatus(activo)
                        Swal.fire(
                            'Ocurrió un error al desactivarlo',
                            'Contacta al desarrollador',
                            'warning'
                        )
                    }
                    
                })
            }
        })
    } 

    const onClickDelete = () => {
        Swal.fire({
            title: 'Seguro que deseas eliminarlo?',
            showCancelButton: true,
            confirmButtonText: 'Si, seguro',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_historial_vehiculo_concesion_economico', id_historial_vehiculo_concesion_economico)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                deleteAsignacionVehiculoConcesionEconomico({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        // Queda pendiente averiguar porque no se puede ejecutar el getReloadElements
                        
                        Swal.fire({
                            title:'Eliminado',
                            timer:1000,
                            showConfirmButton:false,
                            icon:'success'
                        }).then(()=> {
                            getReloadElements()
                            window.location.reload()
                        })
                    }
                    
                    if (_respuesta.response === "error") {
                        Swal.fire({
                            title:'No se puede eliminar',
                            text:"Existen registros vinculados a este dato, no se puede eliminar hasta que elimines todos los registros vinculados a él",
                            showConfirmButton:true,
                            icon:'info'
                        })
                    }
                    
                })
            }
        })
    }

    return (
        <tr key={id_historial_vehiculo_concesion_economico}>
            <th>
                <div className="table__name">
                    {
                        ( (permisosUsuario[0].editar === '1' && estadoEstatus === '1') || permisosUsuario[0].eliminar === '1') &&
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {/* <Link to={`/vehiculos/${id_economico}`} className="dropdown-item">Ver</Link> */}
                                    {/* <a className="dropdown-item" href="#">Asignar permisionario</a>
                                    <a className="dropdown-item" href="#">Asignar concesión</a> */}
                                    {
                                        permisosUsuario[0].editar === '1' &&
                                        estadoEstatus === '1' &&
                                            <a href className="dropdown-item" onClick={() => onClickEstatus(false)}>
                                                Desactivar
                                            </a>
                                    }

                                    {
                                        permisosUsuario[0].eliminar === '1' &&
                                            <a href className="dropdown-item bg-danger text-white" onClick={() => onClickDelete()}>
                                                Eliminar
                                            </a>
                                    }
                                </div>
                            </div>
                    }
                    <div className="table__fechaAlta">
                        <p>Clave: {clave_concesion}</p>
                    </div>
                </div>
            </th>

            <td>
                <p>
                    {prefijo_clave}
                    {numero}
                </p>
                <small>
                  <span className='font-weight-bold'>Ruta:</span> {nombre_derrotero}
                </small>
                <br />
            </td>

            <td>
                <div className="table__fechaAlta">
                    <p>{fecha_asignacion}</p>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ estadoEstatus === '1' ? '' : 'table__estatus-inactive' }>
                        { estadoEstatus === '1' ? 'Activo' : 'Inactivo' }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}