import TableElementHistorialPermisionario from './TableElementHistorialPermisionario';
import TableElementHistorialVehiculo from './TableElementHistorialVehiculo';
import TableElementHistorialEconomico from './TableElementHistorialEconomico';
import TableElementHistorialConcesiones from './TableElementHistorialConcesiones.js';
// import TableElementHistorialClaves from './TableElementHistorialClaves';
import TableElementHistorialTrabajadores from './TableElementHistorialTrabajadores';
import TableElementHistorialConcesionesEconomicos from './TableElementHistorialConcesionesEconomicos';
import TableElementHistorialEconomicosByDerrotero from './TableElementHistorialEconomicosByDerrotero';

export default function TableBodyHistorial({ elements, type, permisosUsuario, getReloadElements }){

    function renderTableElements(){
        if(type === "permisionarios"){
            return elements.map((element) => <TableElementHistorialPermisionario key={element.id_permisionario} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(type === "vehiculos"){
            return elements.map((element) => <TableElementHistorialVehiculo key={element.id_vehiculo} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(type === "economicos"){
            return elements.map((element) => <TableElementHistorialEconomico key={element.id_economico} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(type === "economicosByDerrotero"){
            return elements.map((element) => <TableElementHistorialEconomicosByDerrotero key={element.id_historial_derrotero_economico} element={element} permisosUsuario={permisosUsuario} getReloadElements={getReloadElements} />);
        }
        
        if(type === "concesiones"){
            return elements.map((element) => <TableElementHistorialConcesiones key={element.id_concesion} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        // if(type === "claves"){
        //     return elements.map((element) => <TableElementHistorialClaves key={element.clave} element={element} permisosUsuario={permisosUsuario} />);
        // }
        
        if(type === "trabajadores"){
            return elements.map((element) => <TableElementHistorialTrabajadores key={element.id_trabajador} element={element} permisosUsuario={permisosUsuario} />);
        }
        
        if(type === "concesionesEconomicos"){
            return elements.map((element) => <TableElementHistorialConcesionesEconomicos key={element.id_historial_vehiculo_concesion_economico} element={element} permisosUsuario={permisosUsuario} getReloadElements={getReloadElements} />);
        }
        
    }

    return(
        <tbody>

            {   
                renderTableElements()
            }

        </tbody>
    )
}