import React from 'react';
import { handleErrorsInputFile } from './../../validators/handleErrorsInputFile';
import { useHistory } from "react-router-dom"
import { validateNoNumbers } from './../../validators/validateNoNumbers';
import getPermisosSistemaService from "./../../services/getPermisosSistemaService.js";
import addTrabajadorService from "./../../services/addTrabajadorService.js";
import Icon from '../../components/Icon';
import $ from 'jquery';
import Swal from 'sweetalert2'
import config from '../../config';
import getCantidadFianzaActive from '../../services/catalogos/cantidad_fianza/getCantidadFianzaActive';
import getAreasEmpresaActivos from '../../services/catalogos/area_empresa/getAreasEmpresaActivos';
const URL_API = config.urlApi;

export default function AddTrabajador(){

    const history = useHistory();

    const [areasEmpresa, setAreasEmpresa] = React.useState([]);
    const [tiposTrabajadores, setTiposTrabajadores] = React.useState([]);
    const [estadosCiviles, setEstadosCiviles] = React.useState([]);
    const [estados, setEstados] = React.useState([]);
    const [municipios, setMunicipios] = React.useState([]);
    const [generos, setGeneros] = React.useState([]);
    const [permisos, setPermisos] = React.useState([]);
    const [religiones, setReligiones] = React.useState([]);
    const [cantidadFianzas, setCantidadFianzas] = React.useState([]);

    const [nombre, setNombre] = React.useState('');
    const [apellidoPaterno, setApellidoPaterno] = React.useState('');
    const [apellidoMaterno, setApellidoMaterno] = React.useState('');
    const [fechaNacimiento, setFechaNacimiento] = React.useState('');
    const [genero, setGenero] = React.useState('1');
    const [celular, setCelular] = React.useState('');
    const [local, setLocal] = React.useState('');
    const [correo, setCorreo] = React.useState('');
    const [curp, setCurp] = React.useState('');
    
    const [nombreDeLaCalle, setNombreDeLaCalle] = React.useState('');
    const [numeroExterior, setNumeroExterior] = React.useState('');
    const [numeroInterior, setNumeroInterior] = React.useState('');
    const [referencias, setReferencias] = React.useState('');
    const [colonia, setColonia] = React.useState('');
    const [estado, setEstado] = React.useState('');
    const [municipio, setMunicipio] = React.useState('1');
    const [codigoPostal, setCodigoPostal] = React.useState('');

    const [emailAcceso, setEmailAcceso] = React.useState('');
    const [passwordAcceso, setPasswordAcceso] = React.useState('');
    const [permisosAcceso, setPermisosAcceso] = React.useState();

    const [tipoDeIdentificacion, setTipoDeIdentificacion] = React.useState('');
    const [RFC, setRFC] = React.useState('');
    const [numeroSeguridadSocial, setNumeroSeguridadSocial] = React.useState('');
    const [fechaIngreso, setFechaIngreso] = React.useState('');
    const [estadoCivil, setEstadoCivil] = React.useState('1');
    const [idCreenciaReligiosa, setIdCreenciaReligiosa] = React.useState('');
    const [esposa, setEsposa] = React.useState('');
    const [contactoEmergencia, setContactoEmergencia] = React.useState('');
    const [comentarios, setComentarios] = React.useState('');

    const getAreasEmpresaService = () => {
        getAreasEmpresaActivos()
            .then(res => {
                let _res = JSON.parse(res)
                if(_res.response === 'success')
                    setAreasEmpresa(_res.data)
                $('.selectpicker').selectpicker('refresh');
            })
            .catch(err => console.log(err))
    }

    const handleSetTipoDeIdentificacion = (value) => {
        let tipoTrabajadoresSeleccionados = $("#tipoDeIdentificacion").val();
        // Si seleccionó el tipo trabajador de "Director", mostraremos el input
        // para que seleccione de que área es director.
        if(tipoTrabajadoresSeleccionados.some((val => val === '1'))){
            document.getElementsByClassName('director_select').item(0).classList.remove('hidden')
            $('#area_director').prop('disabled', false)
            $('#area_director').attr('readonly', false)
        } else {
            document.getElementsByClassName('director_select').item(0).classList.add('hidden')
            $('#area_director').prop('disabled', true)
            $('#area_director').attr('readonly', true)
            $('#area_director').val('')
        }
        // Verificar que tipo de trabajador selecciono, y deshabilitar el campo
        // para escribir el area asignada a el cuando sea un 'checador, posturero y
        // tomatiempos'.
        if( tipoTrabajadoresSeleccionados.some((value => (value === '4' || value === '8' || value === '9'))) ){
            document.getElementsByClassName('areas_de_trabajo_select').item(0).classList.add('hidden')
            $('#area_trabajo').prop('disabled', true)
            $('#area_trabajo').attr('readonly', true)
            $('#area_trabajo').val('')
        } else {
            document.getElementsByClassName('areas_de_trabajo_select').item(0).classList.remove('hidden')
            $('#area_trabajo').prop('disabled', false)
            $('#area_trabajo').attr('readonly', false)
        }
        setTipoDeIdentificacion(value)
        $('.selectpicker').selectpicker('refresh');
    }

    function onChangeImageInput(e){
        const file = e.target.files[0];
        document.getElementById("perfil__imagen").src = URL.createObjectURL(file);
        $('#perfil__imagen').removeClass("hidden")
    }

    function onChangeFileInput(e, nombreInput){
        const file = e.target.files[0];
        document.getElementById(nombreInput+'Preview').src = URL.createObjectURL(file);
        $('#'+nombreInput+'Preview').removeClass("hidden")
    }

    function onChangeEstado(value){
        setEstado(value)
        getMunicipios(value)
    }

    function getMunicipios(estado){
        $.ajax({
            data:{estado:estado},
            url: URL_API+'controlador/getMunicipios.controlador.php',
            type:"POST",
            success: function(response){
                let _respuesta = JSON.parse(response)
                if(!(_respuesta.response === 'error')){
                    setMunicipios(_respuesta.municipios)
                    $("#municipio").attr("disabled",false)
                    $('.selectpicker').selectpicker('refresh')
                }
            }
        })
    }

    function getDataForm(){
        $.ajax({
            url: URL_API+'controlador/getDataAltaTrabajador.controlador.php',
            success: function(response){
                let _respuesta = JSON.parse(response)
                // console.log(_respuesta)
                if(!(_respuesta.response === 'error')){
                    setTiposTrabajadores(_respuesta.tipos_trabajadores)
                    setEstadosCiviles(_respuesta.estados_civiles)
                    setEstados(_respuesta.estados)
                    setGeneros(_respuesta.generos)
                    setReligiones(_respuesta.creencia_religiosa)
                    $('.selectpicker').selectpicker('refresh')
                }
            }

        })
    }

    function onSubmitTrabajador(e){
        e.preventDefault()
        let fotoPerfil = document.getElementById('fotoPerfil').files;
        let actaDeNacimiento = document.getElementById("actaDeNacimiento").files;
        let rfcDocumento = document.getElementById("rfcDocumento").files;
        let comprobanteDomicilio = document.getElementById("comprobanteDomicilio").files;
        let INEVistaFrontal = document.getElementById("INEVistaFrontal").files;
        let INEVistaTrasera = document.getElementById("INEVistaTrasera").files;
        let boletaPredial = document.getElementById("boletaPredial").files;
        // let ticketFianza = document.getElementById("url_pdf_ticket_fianza").files;
        
        if( handleErrorsInputFile(fotoPerfil, "Foto de perfil") || 
            handleErrorsInputFile(actaDeNacimiento, "Acta de nacimiento") || 
            handleErrorsInputFile(actaDeNacimiento, "Acta de nacimiento") || 
            handleErrorsInputFile(rfcDocumento, "RFC") || 
            handleErrorsInputFile(comprobanteDomicilio, "Comprobante de domicilio") ||
            handleErrorsInputFile(INEVistaFrontal, "INE Vista frontal") ||
            handleErrorsInputFile(INEVistaTrasera, "INE Vista trasera") ||
            handleErrorsInputFile(boletaPredial, "Boleta de predial") ){
            return;
        }

        let values = new FormData(e.target)
        let permisosSistemaSelected = $("#permisosAcceso").val();
        let tipoTrabajadoresSeleccionados = $("#tipoDeIdentificacion").val();
        let areasEnQueTrabaja = $("#area_trabajo").val();
        let areaEnQueEsDirector = $("#area_director").val();
        values.append("permisosSistemaSelected",JSON.stringify(permisosSistemaSelected));
        values.append("tipoTrabajadoresSeleccionados",JSON.stringify(tipoTrabajadoresSeleccionados));
        values.append("areasEnQueTrabajaSeleccionadas",JSON.stringify(areasEnQueTrabaja));
        values.append("areaEnQueEsDirectorSeleccionadas",JSON.stringify(areaEnQueEsDirector));
        const user = JSON.parse(localStorage.getItem("user"))
        const id = user.id_usuario
        values.append('id',id);
        
        let form = new FormData()
        values.forEach((data, key)=>form.append(key, data))

        // console.log(Object.fromEntries(form));

        // console.log({tipoTrabajadoresSeleccionados, areasEnQueTrabaja, areaEnQueEsDirector})

        addTrabajadorService({values:values})
            .then(respuesta=>{
                console.log(respuesta)
                let _respuesta = JSON.parse(respuesta);
                console.log(_respuesta)
                if(_respuesta.response === 'success'){
                    Swal.hideLoading();
                    Swal.fire({
                        title:"Trabajador agregado",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(function(){
                        // history.push(`/trabajador`)
                    })
                } else {
                    Swal.fire(
                        'Ocurrió un error',
                        'Vuelve a intentarlo',
                        'error'
                    )
                }
            })
            .catch(err => console.log(err))
    }

    function getPermisosSistema(){
        getPermisosSistemaService().then(response => {
            let _respuesta = JSON.parse(response);
            // console.log(_respuesta)
            if(_respuesta.response === "error"){
                Swal.fire(
                    'Error al cargar permisos',
                    'No existe ningún permiso o hubo un error en la conexión, inténtalo de nuevo recargando la página.',
                    'error'
                )
            } else {
                setPermisos(_respuesta.permisos);
                $('.selectpicker').selectpicker('refresh')
            }

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    function getCantidadFianzaActiveService(){
        getCantidadFianzaActive().then(response => {
            let _respuesta = JSON.parse(response);
            // console.log(_respuesta)
            if(_respuesta.response === "error"){
                Swal.fire(
                    'Error al cargar las fianzas',
                    'No existe ninguna cantidad de fianza en el catálogo.',
                    'error'
                )
            } else {
                setCantidadFianzas(_respuesta.data);
                $('.selectpicker').selectpicker('refresh')
            }

        }).catch((error) => {
            console.log(error)
            console.log("ha petado")
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    React.useEffect(() => {
        getDataForm();
        getPermisosSistema();
        getCantidadFianzaActiveService();
        getAreasEmpresaService();
        $('.selectpicker').selectpicker();
    }, []);

    return(
        <section>
            <input
                action="action"
                onClick={() => {window.history.go(-1); return false;}}
                type="submit"
                value="Regresar"
                className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
            />

            <h2 className="mt-5">Agregar un trabajador</h2>

            <p>
                Para agregar un trabajador debes completar el siguiente formulario
            </p>

            <form className="formulario" onSubmit={ onSubmitTrabajador }>

                <div className="perfil__fotoPerfil">
                    <div className="perfil__miniatura">
                        <img id="perfil__imagen" className="perfil__imagen img-fluid" src="https://via.placeholder.com/500/FF7925/fff?text=Foto perfil" />
                    </div>
                    <input type="file" id="fotoPerfil" className="hidden" accept="image/png, image/jpeg" onChange={ (e) => onChangeImageInput(e) } name="fotoPerfil" />
                    <div>
                        <label htmlFor="fotoPerfil" className="perfil__cambiarFoto">
                            <h3>
                                Foto del trabajador
                            </h3>
                            <p>
                                Da clic aquí para cargar una foto de perfil al trabajador
                            </p>
                        </label>
                    </div>
                </div>

                {/* ****************************** */}
                {/* BLOQUE DE LOS DATOS PERSONALES */}
                {/* ****************************** */}
            
                <div className="datosPersonales my-5">
                    <h3>
                        Datos personales
                    </h3>

                    <div className="datosPersonales__formulario row">
                        <div className="col-4">
                            <label htmlFor="nombre">
                                Nombre(s):
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="nombre"
                                name="nombre"
                                value={ nombre }
                                onChange = { (e) => setNombre(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="apellidoPaterno">
                                Apellido paterno:
                            </label>
                            <input 
                                type="text" 
                                className="input" 
                                id="apellidoPaterno"
                                name="apellidoPaterno"
                                value={ apellidoPaterno }
                                onChange = { (e) => setApellidoPaterno(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="apellidoMaterno">
                                Apellido materno:
                            </label>
                            <input 
                                type="text"
                                className="input" 
                                id="apellidoMaterno"
                                name="apellidoMaterno"
                                value={ apellidoMaterno }
                                onChange = { (e) => setApellidoMaterno(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="fechaNacimiento">
                                Fecha nacimiento:
                            </label>
                            <input 
                                type="date"
                                className="input"
                                id="fechaNacimiento"
                                name="fechaNacimiento"
                                value={ fechaNacimiento }
                                onChange = { (e) => setFechaNacimiento(e.target.value) }
                                required
                                />
                        </div>
                        <div className="col-4">
                            <label htmlFor="genero">
                                Género:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="genero"
                                name="genero"
                                value={ genero }
                                onChange = { (e) => setGenero(e.target.value) }
                                required
                            >
                                {
                                    generos && 
                                    generos.map( genero => (
                                        <option key={ genero.id_genero } value={ genero.id_genero }>
                                            { genero.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="celular">
                                Número de celular:
                            </label>
                            <input 
                                type="tel" 
                                placeholder="#########" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="celular"
                                name="celular"
                                value={ celular }
                                onChange = { (e) => setCelular(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="local">
                                Número local:
                            </label>
                            <input 
                                type="tel" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                maxLength="15"
                                id="local"
                                name="local"
                                value={ local }
                                onChange = { (e) => setLocal(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="correo">
                                Correo electrónico:
                            </label>
                            <input 
                                type="email" 
                                className="input"
                                id="correo"
                                name="correo"
                                value={ correo }
                                onChange = { (e) => setCorreo(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="curp">
                                CURP:
                            </label>
                            <input 
                                type="text" 
                                className="input"
                                id="curp"
                                name="curp"
                                maxLength="20"
                                value={ curp }
                                onChange = { (e) => setCurp(e.target.value) }
                                required
                            />
                        </div>
                    </div>

                </div>
               
               
                {/* ********************************* */}
                {/* BLOQUE DE LOS DATOS DEL DOMICILIO */}
                {/* ********************************* */}
            
                <div className="datosDomicilio my-5">
                    <h3>
                        Datos de domicilio
                    </h3>

                    <div className="datosDomicilio__formulario row">
                        <div className="col-4">
                            <label htmlFor="nombreDeLaCalle">
                                Calle:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="nombreDeLaCalle"
                                name="nombreDeLaCalle"
                                value={ nombreDeLaCalle }
                                onChange = { (e) => setNombreDeLaCalle(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="numeroExterior">
                                No. exterior:
                            </label>
                            <input 
                                type="num" 
                                placeholder="###" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                id="numeroExterior"
                                name="numeroExterior"
                                value={ numeroExterior }
                                onChange = { (e) => setNumeroExterior(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="numeroInterior">
                                No. interior:
                            </label>
                            <input 
                                type="num" 
                                placeholder="opcional" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                id="numeroInterior"
                                name="numeroInterior"
                                value={ numeroInterior }
                                onChange = { (e) => setNumeroInterior(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="referencias">
                                Referencias:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="referencias"
                                name="referencias"
                                value={ referencias }
                                onChange = { (e) => setReferencias(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="colonia">
                                Colonia:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="colonia"
                                name="colonia"
                                value={ colonia }
                                onChange = { (e) => setColonia(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="estado">
                                Estado:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="estado"
                                name="estado"
                                value={ estado }
                                onChange = { (e) => onChangeEstado(e.target.value) }
                                required
                                data-live-search="true"
                            >   
                                <option value="">Elige un estado</option>
                                {
                                    estados.map( (estado) => (
                                        <option key={ estado.id_estado } value={ estado.id_estado } >
                                            { estado.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-2">
                            <label htmlFor="municipio">
                                Municipio:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="municipio"
                                name="municipio"
                                value={ municipio }
                                onChange={ (e) => setMunicipio(e.target.value) }
                                required
                                data-live-search="true"
                                disabled
                            >
                                <option>
                                    Elige un municipio
                                </option>
                                {
                                    municipios.map( (municipio) => (
                                        <option key={ municipio.id_municipio } value={ municipio.id_municipio } >
                                            { municipio.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="codigoPostal">
                                Código postal:
                            </label>
                            <input 
                                type="num" 
                                placeholder="#####" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength="10"
                                id="codigoPostal"
                                name="codigoPostal"
                                value={ codigoPostal }
                                onChange={ (e) => setCodigoPostal(e.target.value) }
                                required
                            />
                        </div>
                    </div>

                </div>
               
               
                {/* ************************************* */}
                {/* BLOQUE DE LOS DATOS DE IDENTIFICACIÓN */}
                {/* ************************************* */}
            
                <div className="datosIdentificacion my-5">
                    <h3>
                        Datos de identificación
                    </h3>

                    <div className="datosIdentificacion__formulario row">
                        <div className="col-6">
                            <label htmlFor="tipoDeIdentificacion">
                                Tipo de trabajador:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="tipoDeIdentificacion"
                                name="tipoDeIdentificacion"
                                multiple
                                // value={ tipoDeIdentificacion }
                                onChange={ (e) => handleSetTipoDeIdentificacion(e.target) }
                                required
                            >
                                {
                                    tiposTrabajadores &&
                                    tiposTrabajadores.map( (tipo) => (
                                        <option key={ tipo.id_tipo_trabajador } value={ tipo.id_tipo_trabajador }>
                                            { tipo.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-3">
                            <div className="areas_de_trabajo_select">
                                <label htmlFor="area_trabajo">
                                    En qué áreas trabaja?
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="area_trabajo"
                                    name="area_trabajo"
                                    multiple
                                >
                                    {
                                        areasEmpresa &&
                                        areasEmpresa.map( (area) => (
                                            <option key={ area.id_area_empresa } value={ area.id_area_empresa }>
                                                { area.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="director_select hidden">
                                <label htmlFor="area_director">
                                    De qué áreas es director?:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="area_director"
                                    name="area_director"
                                    multiple
                                    required
                                >
                                    {
                                        areasEmpresa &&
                                        areasEmpresa.map( (area) => (
                                            <option key={ area.id_area_empresa } value={ area.id_area_empresa }>
                                                { area.nombre }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr className='my-3' />
                    <div className='datosIdentificacion__formulario row'>
                        <div className="col-4">
                            <label htmlFor="RFC">
                                RFC:
                            </label>
                            <input 
                                type="text"
                                placeholder="XXXX#####XXX" 
                                className="input"
                                id="RFC"
                                name="RFC"
                                value={ RFC }
                                onChange={ (e) => setRFC(e.target.value) }
                            />
                        </div>
                        <div className="col-2">
                            <label htmlFor="numeroSeguridadSocial">
                                # Seg. social:
                            </label>
                            <input 
                                type="num" 
                                placeholder="Opcional" 
                                className="input" 
                                onKeyPress={ validateNoNumbers }
                                id="numeroSeguridadSocial"
                                name="numeroSeguridadSocial"
                                value={ numeroSeguridadSocial }
                                onChange={ (e) => setNumeroSeguridadSocial(e.target.value) }
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="fechaIngreso">
                                Fecha ingreso:
                            </label>
                            <input 
                                type="date"
                                className="input" 
                                id="fechaIngreso"
                                name="fechaIngreso"
                                value={ fechaIngreso }
                                onChange={ (e) => setFechaIngreso(e.target.value) }
                                required
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="estadoCivil">
                                Estado civil:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="estadoCivil"  
                                name="estadoCivil"
                                value={ estadoCivil }
                                onChange={ (e) => setEstadoCivil(e.target.value) }
                            >
                                {
                                    estadosCiviles &&
                                    estadosCiviles.map( (estadoCivil) => (
                                        <option key={ estadoCivil.id_estado_civil } value={ estadoCivil.id_estado_civil }>
                                            { estadoCivil.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>

                        <div className="col-4">
                            <label htmlFor="id_creencia_religiosa">
                                Religión:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="id_creencia_religiosa"  
                                name="id_creencia_religiosa"  
                                value={ idCreenciaReligiosa }
                                onChange={ (e) => setIdCreenciaReligiosa(e.target.value) }
                            >
                                <option value="">
                                    Opcional
                                </option>
                                {
                                    religiones &&
                                    religiones.map( (religion) => (
                                        <option key={ religion.id_creencia_religiosa } value={ religion.id_creencia_religiosa }>
                                            { religion.nombre }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>

                        <div className="col-4">
                            <label htmlFor="numero_licencia">
                                Número de licencia:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="numero_licencia"
                                name="numero_licencia"
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="esposa">
                                Sí tiene esposa, escríbelo aquí:
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="esposa"
                                name="esposa"
                                value={ esposa }
                                onChange={ (e) => setEsposa(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="contactoEmergencia">
                                Contacto de emergencia:
                            </label>
                            <input 
                                type="num"
                                onKeyPress={ validateNoNumbers }
                                placeholder="Opcional" 
                                className="input"
                                id="contactoEmergencia"
                                name="contactoEmergencia"
                                value={ contactoEmergencia }
                                onChange={ (e) => setContactoEmergencia(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="tiene_alergias">
                                ¿Tiene alergias?
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_alergias"
                                name="tiene_alergias"
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="tiene_enfermedad_cronica">
                                ¿Tiene alguna enfermedad crónica?
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_enfermedad_cronica"
                                name="tiene_enfermedad_cronica"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_familiar_discapacidad">
                                ¿Tiene familiar con discapacidad física o motriz?
                            </label>
                            <input 
                                type="text"
                                placeholder="Opcional" 
                                className="input"
                                id="tiene_familiar_discapacidad"
                                name="tiene_familiar_discapacidad"
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="tiene_hijos">
                                ¿Tiene hijos?, escribe cuántos:
                            </label>
                            <input 
                                type="num"
                                placeholder="Opcional" 
                                className="input"
                                onKeyPress={ validateNoNumbers }
                                maxLength={2}
                                id="tiene_hijos"
                                name="tiene_hijos"
                            />
                        </div>
                        <div className="col-12">
                            <label htmlFor="comentarios">
                                Anotaciones:
                            </label>
                            <textarea 
                                rows={5}
                                cols={5}
                                type="text"
                                placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre el trabajador" 
                                className="input textarea"
                                id="comentarios"
                                name="comentarios"
                                value={ comentarios }
                                onChange={ (e) => setComentarios(e.target.value) }
                            />
                        </div>
                    </div>

                </div>
               
               
                {/* ************************************* */}
                {/* BLOQUE DE TICKET DE FIANZA TRABAJADOR */}
                {/* ************************************* */}
            
                <div className="datosAccesoSistema my-5">
                    <h3>
                        Datos de fianza
                    </h3>

                    <div className="subidaDocumentos__formulario row mt-4">
                        <div className="col-3">
                            <label htmlFor="id_cantidad_fianza">
                                Cantidad fianza:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="id_cantidad_fianza"  
                                name="id_cantidad_fianza"
                                required
                            >
                                {
                                    cantidadFianzas &&
                                    cantidadFianzas.map( (cantidad_fianza) => (
                                        <option key={ cantidad_fianza.id_cantidad_fianza } value={ cantidad_fianza.id_cantidad_fianza }>
                                            $ { cantidad_fianza.cantidad }
                                        </option>
                                    ) )
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="pago-fianza-permisionario-si">
                                La fianza la pagó el permisionario?
                            </label>
                            <br />

                            <div className="form-check form-check-inline">
                                <input 
                                    type="radio"
                                    name="pago_fianza_permisionario"
                                    value={1}
                                    id={'pago-fianza-permisionario-si'}
                                    required
                                />
                                <label className="form-check-label ml-1" htmlFor="pago-fianza-permisionario-si">
                                    Si
                                </label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input 
                                    type="radio"
                                    name="pago_fianza_permisionario"
                                    value={0}
                                    id={'pago-fianza-permisionario-no'}
                                    checked
                                    required
                                />
                                <label className="form-check-label ml-1" htmlFor="pago-fianza-permisionario-no">
                                    No
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <input type="file" id="url_pdf_ticket_fianza" name="url_pdf_ticket_fianza" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "url_pdf_ticket_fianza") } />
                            <label htmlFor="url_pdf_ticket_fianza">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir ticket de pago fianza
                                </a>
                            </label>
                            <embed
                                id="url_pdf_ticket_fianzaPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>
                    </div>

                </div>

                {/* **************************************** */}
                {/* BLOQUE DE LOS DATOS DE ACCESO AL SISTEMA */}
                {/* **************************************** */}
            
                {/* <div className="datosAccesoSistema my-5">
                    <h3>
                        Datos de acceso al sistema
                    </h3>

                    <p>
                        Sí quieres que el trabajador <span className="font-weight-bold">tenga acceso al sistema</span>, completa los siguientes campos:
                    </p>

                    <div className="datosAccesoSistema__formulario row mt-4">
                        <div className="col-4">
                            <label htmlFor="emailAcceso">
                                Correo de acceso:
                            </label>
                            <input 
                                type="email"
                                placeholder="Opcional" 
                                className="input"
                                id="emailAcceso"
                                name="emailAcceso"
                                value={ emailAcceso }
                                onChange={ (e) => setEmailAcceso(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="passwordAcceso">
                                Contraseña:
                            </label>
                            <input 
                                type="text"
                                className="input"
                                id="passwordAcceso"
                                name="passwordAcceso"
                                value={ passwordAcceso }
                                onChange={ (e) => setPasswordAcceso(e.target.value) }
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="permisosAcceso">
                                Permisos:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="permisosAcceso"
                                multiple
                                name="permisosAcceso"
                                value={ permisosAcceso }
                            >
                                {
                                    permisos &&
                                    permisos.map( (permiso) => (
                                        <optgroup key={ permiso.id_permiso } label={ permiso.nombre_modulo }>
                                            <option value={ `${permiso.id_permiso}_leer` }>Leer</option>
                                            <option value={ `${permiso.id_permiso}_escribir` }>Escribir</option>
                                            <option value={ `${permiso.id_permiso}_editar` }>Editar</option>
                                            <option value={ `${permiso.id_permiso}_eliminar` }>Eliminar</option>
                                        </optgroup>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                </div> */}

                {/* ********************************* */}
                {/* BLOQUE DE LA SUBIDA DE DOCUMENTOS */}
                {/* ********************************* */}

                <div className="subidaDocumentos my-5">
                    <h3>
                        Documentos personales
                    </h3>
                    
                    <p>
                        Tienes que subir cada documento por separado, da clic en el botón del pdf que quieres subir y selecciona el archivo.
                    </p>

                    <div className="subidaDocumentos__formulario row mt-3">
                        <div className="col-4">
                            <input type="file" id="actaDeNacimiento" className="hidden" accept="application/pdf,application/vnd.ms-excel" name="actaDeNacimiento" onChange={ (e) => onChangeFileInput(e, "actaDeNacimiento") } />
                            <label htmlFor="actaDeNacimiento">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir acta de nacimiento
                                </a>
                            </label>
                            <embed
                                id="actaDeNacimientoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>
                        
                        <div className="col-4">
                            <input type="file" id="rfcDocumento" name="rfcDocumento" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "rfcDocumento") } />
                            <label htmlFor="rfcDocumento">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir documento de RFC
                                </a>
                            </label>
                            <embed
                                id="rfcDocumentoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="comprobanteDomicilio" name="comprobanteDomicilio" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "comprobanteDomicilio") } />
                            <label htmlFor="comprobanteDomicilio">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Subir comprobante de domicilio
                                </a>
                            </label>
                            <embed
                                id="comprobanteDomicilioPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="INEVistaFrontal" name="INEVistaFrontal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "INEVistaFrontal") } />
                            <label htmlFor="INEVistaFrontal">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    INE (vista frontal)
                                </a>
                            </label>
                            <embed
                                id="INEVistaFrontalPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="INEVistaTrasera" name="INEVistaTrasera" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "INEVistaTrasera") } />
                            <label htmlFor="INEVistaTrasera">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    INE (vista trasera)
                                </a>
                            </label>
                            <embed
                                id="INEVistaTraseraPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="boletaPredial" name="boletaPredial" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "boletaPredial") } />
                            <label htmlFor="boletaPredial">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Boleta predial
                                </a>
                            </label>
                            <embed
                                id="boletaPredialPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="cartaReferenciaComercial" name="cartaReferenciaComercial" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "cartaReferenciaComercial") } />
                            <label htmlFor="cartaReferenciaComercial">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Carta de referencia comercial
                                </a>
                            </label>
                            <embed
                                id="cartaReferenciaComercialPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="cartasReferenciaPersonal" name="cartasReferenciaPersonal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "cartasReferenciaPersonal") } />
                            <label htmlFor="cartasReferenciaPersonal">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Cartas de referencia personal
                                </a>
                            </label>
                            <embed
                                id="cartasReferenciaPersonalPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="actaMatrimonio" name="actaMatrimonio" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "actaMatrimonio") } />
                            <label htmlFor="actaMatrimonio">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Acta de matrimonio
                                </a>
                            </label>
                            <embed
                                id="actaMatrimonioPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="estudioSocioeconomico" name="estudioSocioeconomico" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "estudioSocioeconomico") } />
                            <label htmlFor="estudioSocioeconomico">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Estudio socioeconómico
                                </a>
                            </label>
                            <embed
                                id="estudioSocioeconomicoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="antecedentesPenales" name="antecedentesPenales" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "antecedentesPenales") } />
                            <label htmlFor="antecedentesPenales">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Antecendentes penales
                                </a>
                            </label>
                            <embed
                                id="antecedentesPenalesPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-4">
                            <input type="file" id="solicitudDeIngreso" name="solicitudDeIngreso" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "solicitudDeIngreso") } />
                            <label htmlFor="solicitudDeIngreso">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Solicitud de ingreso
                                </a>
                            </label>
                            <embed
                                id="solicitudDeIngresoPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-6">
                            <input type="file" id="licenciaConducirFrontal" name="licenciaConducirFrontal" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "licenciaConducirFrontal") } />
                            <label htmlFor="licenciaConducirFrontal">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Licencia de conducir (vista frontal)
                                </a>
                            </label>
                            <embed
                                id="licenciaConducirFrontalPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                        <div className="col-6">
                            <input type="file" id="licenciaConducirTrasera" name="licenciaConducirTrasera" className="hidden" accept="application/pdf,application/vnd.ms-excel" onChange={ (e) => onChangeFileInput(e, "licenciaConducirTrasera") } />
                            <label htmlFor="licenciaConducirTrasera">
                                <a type="button" className="btnFile">
                                    <Icon icon="fa-solid fa-file-arrow-up mr-2" />
                                    Licencia de conducir (vista trasera)
                                </a>
                            </label>
                            <embed
                                id="licenciaConducirTraseraPreview"
                                className="previewPDF hidden"
                                width="250"
                                height="200">
                            </embed>
                        </div>

                    </div>

                </div>

                {/* ******************************* */}
                {/* BOTÓN PARA ENVIAR EL FORMULARIO */}
                {/* ******************************* */}

                <hr></hr>
                <div className="d-flex justify-content-end">
                    <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                        <Icon icon="fa-solid fa-plus" />
                        <Icon icon="fa-solid fa-briefcase mr-3" />
                        Agregar trabajador
                    </button>
                </div>


            </form>


        </section>
    )
}