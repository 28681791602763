import React from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';
import Swal from 'sweetalert2'
import $ from 'jquery';
import FormularioVehiculo from '../../components/FormularioVehiculo';
import FormularioFacturasVehiculo from '../../components/FormularioFacturasVehiculo';
import CardAccordion from '../../components/CardAccordion';
import FormularioDocumentosVehículo from '../../components/FormularioDocumentosVehículo';
import getVehiculoService from "./../../services/getVehiculoService.js";
import changeInformacionVehiculoService from './../../services/changeInformacionVehiculoService';
import changeEstatusVehiculoService from './../../services/changeEstatusVehiculoService';

import ModalAsignacion from '../../components/ModalAsignacion';
import ModalAsignacionVehiculo from '../../components/ModalAsignacionVehiculo';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBodyHistorial from "../../components/Table/TableBodyHistorial";

import getOperadoresPermisionariosActivosService from "./../../services/getOperadoresPermisionariosActivosService.js";
import getOperadoresByVehiculoService from "./../../services/getOperadoresByVehiculoService.js";
import addAsignacionOperadorByVehiculoService from "../../services/addAsignacionOperadorByVehiculoService.js";

import getPermisionariosByVehiculoService from "./../../services/getPermisionariosByVehiculoService.js";
import getPermisionariosActivosService from "./../../services/getPermisionariosActivosService.js";
import addAsignacionPermisionarioByVehiculoService from "../../services/addAsignacionPermisionarioByVehiculoService.js";
import getConcesionesEconomicosByVehiculoService from '../../services/getConcesionesEconomicosByVehiculoService';

export default function ViewVehiculo({permisosUsuario}) {

    const location = useLocation();
    const id_vehiculo = location.pathname.split('/')[2];

    const [vehiculo, setVehiculo] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [mensajeVigencia, setMensajeVigencia] = React.useState('');
    const [estatusVigencia, setEstatusVigencia] = React.useState('');

    const getFlagVigencia = (fecha_vigencia) =>{
        let fechaVigencia = new Date(fecha_vigencia).getTime()
        let fechaActual = new Date().getTime()
        let diferenciaEnDias = ((fechaVigencia - fechaActual)/(1000*60*60*24)).toFixed(0)
        if(diferenciaEnDias <= 0){
            setEstatusVigencia('table__estatus-inactive')
            setMensajeVigencia('Expiró')
            return;
        }
        
        if(diferenciaEnDias <= 30 && diferenciaEnDias >= 7){
            setMensajeVigencia('Próximo a vencer')
            setEstatusVigencia('table__estatus-warning')
            return;
        }
        
        if(diferenciaEnDias <= 7 && diferenciaEnDias >= 1){
            console.log('vence en '+diferenciaEnDias+' dias')
            if(diferenciaEnDias > 1)
                setMensajeVigencia('Vence en '+diferenciaEnDias+' días')
            else
                setMensajeVigencia('Vence en '+diferenciaEnDias+' día')
            
            setEstatusVigencia('table__estatus-warning')
            return;
        }

        setMensajeVigencia('Vigente')

    }

    function getVehiculo(){
        let data = {
            id_vehiculo: id_vehiculo
        }
        getVehiculoService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    // console.log(_response)
                    setVehiculo(_response.vehiculo);
                    // console.log(_response.vehiculo.fecha_vigencia_seguro)
                    getFlagVigencia(_response.vehiculo.fecha_vigencia_seguro)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.log(error);
                console.log("ha petado")
            })
    }

    function onSubmitUpdateVehiculo(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_vehiculo', id_vehiculo);
        let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        changeInformacionVehiculoService({values:objectValues})
            .then(response => {
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                    getVehiculo();
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {
        let msg = (activo) ? 'Quieres habilitar el vehículo?' : 'Quieres deshabilitar el vehículo?';
        let txt = (activo) ? 'Habilitar' : 'Deshabilitar';
        Swal.fire({
            title: msg,
            showCancelButton: true,
            confirmButtonText: txt,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                let values = new FormData()
                values.append('id_vehiculo', id_vehiculo)
                values.append('activo', activo)
                let objectValues = Object.fromEntries(values);
                console.log({objectValues})
                changeEstatusVehiculoService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getVehiculo()
                        Swal.fire(
                            'Estatus actualizado',
                            '',
                            'success'
                        )
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////
    // FUNCIONES PARA EL HISTORIAL DE OPERADORES

    const [operadores, setOperadores] = React.useState([]);
    const [operadoresByPermisionario, setOperadoresByPermisionario] = React.useState([]);

    const rowsTableOperadores = [
        { id: 1, name: "Trabajador" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getOperadoresPermisionariosActivos = () =>{
        setOperadores([])
        let data = {
            id_vehiculo: id_vehiculo
        }
        getOperadoresPermisionariosActivosService({values:data})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){}else{
                    setOperadores(_response)
                    $(".selectpicker").selectpicker('refresh');
                }
            })
            .catch(error => {
                console.log("ha petado getOperadores() de ViewPermisionario.js: ", error)
            })
    }

    const getOperadoresByVehiculo = () =>{
        setOperadoresByPermisionario([])
        let data = {
            id_vehiculo: id_vehiculo
        }
        getOperadoresByVehiculoService({values:data})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setOperadoresByPermisionario(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getOperadoresByVehiculo() de ViewPermisionario.js: ", error)
            })
    }

    const onSubmitAsignacionOperador = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_vehiculo', id_vehiculo)
        let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        Swal.fire({
            title: "¿Seguro que deseas asignarle el operador?",
            text: "De ahora en adelante, el operador podrá operar el vehículo.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionOperadorByVehiculoService({values:objectValues}).then(response => {
                    // console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getOperadoresByVehiculo();
                        $("#modalAsignacionOperador").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////

    // ///////////////////////////////////////////////
    // FUNCIONES PARA EL HISTORIAL DE PERMISIONARIOS

    const [permisionarios, setPermisionarios] = React.useState([]);
    const [allPermisionarios, setAllPermisionarios] = React.useState([]);

    const getTodosLosPermisionarios = () => {
        setAllPermisionarios([])
        getPermisionariosActivosService()
        .then((respuesta) => {
            let _respuesta = JSON.parse(respuesta)
            console.log(_respuesta)
            setAllPermisionarios(_respuesta)
        })
        .catch((error) => console.log(error))
    }

    const getPermisionariosByVehiculo = () =>{
        setPermisionarios([])
        let data = {
            id_vehiculo: id_vehiculo
        }
        getPermisionariosByVehiculoService({values:data})
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setPermisionarios(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getPermisionariosByVehiculo() de ViewVehiculo.js: ", error)
            })
    }

    const onSubmitAsignacionPermisionario = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_vehiculo', id_vehiculo)
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        Swal.fire({
            title: "¿Seguro que deseas asignarle el permisionario?",
            text: "De ahora en adelante, este socio será dueño de la unidad.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionPermisionarioByVehiculoService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getPermisionariosByVehiculo();
                        setTimeout(getOperadoresByVehiculo, 400)
                        setTimeout(getOperadoresPermisionariosActivos, 800)
                        $("#modalAsignacionPermisionario").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////
    // FUNCIONES PARA EL HISTORIAL DE CONCESIONES, ECONOMICOS

    const [concesionesEconomicos, setConcesionesEconomicos] = React.useState([]);

    const rowsTableVehiculosConcesionesEconomicos = [
        { id: 1, name: "Concesión" },
        { id: 2, name: "Económico" },
        { id: 3, name: "Fecha asignación" },
        { id: 4, name: "Estatus" }
    ];

    const getConcesionesEconomicosByVehiculo = () =>{
        setConcesionesEconomicos([])
        let data = {
            id_vehiculo: id_vehiculo
        }
        getConcesionesEconomicosByVehiculoService({values:data})
            .then(response => {
                console.log(response)
                let _response = JSON.parse(response)
                console.log(_response)
                if(_response.response === 'success'){
                    setConcesionesEconomicos(_response.data)
                } 
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getConcesionesEconomicosByVehiculo() de ViewVehiculo.js: ", error)
            })
    }

    const onClickPonerUnidadEnFuncionamiento = (dataPermisionarios) => {
        // Verificar primero si la unidad ya tiene un permisionario vinculado como dueño.
        // Esto es porque para poner en funcionamiento se necesita tener un operador del dueño
        // de la unidad
        if( dataPermisionarios.length === 0 || 
            !(dataPermisionarios.some(permisionario => permisionario.activo === '1')) ){
            // Mostrar alerta de que debe vincular primero un dueño del vehīculo
            Swal.fire({
                title: "El vehículo debe tener un permisionario activo",
                text: 'No se ha encontrado ningún permisionario vinculado a este vehículo como propietario activo. Por favor, vincula el vehículo a un permisionario haciendo clic en la sección "Historial de permisionarios"',
                icon: 'info'
            })
            return;
        }

        // Si todo está bien, abrimos el modal
        $("#modalAsignacionVehiculo").modal('show')
    }

    const onClickAsignarOperador = (dataOperadores) => {
        // Verificar primero si la unidad ya tiene un permisionario vinculado como dueño.
        // Esto es porque para poner en funcionamiento se necesita tener un operador del dueño
        // de la unidad
        if( dataOperadores.length === 0 ){
            // Mostrar alerta de que debe vincular primero un dueño del vehīculo
            Swal.fire({
                title: "El permisionario no tiene operadores vinculados",
                text: 'No se ha encontrado ningún operador vinculado al permisionario propietario de este vehículo. Por favor, vincula al menos un operador al permisionario haciendo clic en la sección "Operadores" de la vista del permisionario',
                icon: 'info'
            })
            return;
        }

        // Si todo está bien, abrimos el modal
        $("#modalAsignacionOperador").modal('show')
    }

    function getReloadElements(){
        getConcesionesEconomicosByVehiculo()
    }

    React.useEffect(() => {
        getVehiculo()

        getOperadoresPermisionariosActivos()
        getOperadoresByVehiculo()

        getTodosLosPermisionarios()
        getPermisionariosByVehiculo()

        getConcesionesEconomicosByVehiculo()
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información del vehículo:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3 text-center">
    
                        <h2 className="my-2">
                            Número de serie:
                            <br></br>
                            {
                                vehiculo.numero_serie
                            }
                        </h2>
    
                        <div className="d-flex flex-column align-items-center">
                            <span className={`flag ${ vehiculo.activo == 1 ? '' : 'flag-inactive' }`}>
                                { vehiculo.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            {/* <p className="mt-2">
                                <small>
                                    Fecha de inicio de operaciones: <br/> {vehiculo.fecha_operacion_formato}
                                </small>
                            </p> */}
                            <p className="mt-2">
                                <small>
                                    Alta en sistema: <br/> {vehiculo.fecha_alta}
                                </small>
                            </p>
                            <p className="mt-2">
                                <small>
                                    Fecha de vigencia del seguro: <br/>{vehiculo.vigencia_seguro}
                                </small>
                            </p>
                            <div className="table__estatus justify-content-center mt-2">
                                <span className={ estatusVigencia }>
                                    {mensajeVigencia}
                                </span>
                            </div>
                        </div>

    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fa-solid fa-truck-moving mr-2" />
                                Vehículo
                            </a>
                            <a className="nav-link" id="v-pills-prorrogas-tab" data-toggle="pill" href="#v-pills-prorrogas" role="tab" aria-controls="v-pills-prorrogas" aria-selected="true">
                                <Icon icon="fa-solid fa-file-invoice mr-2" />
                                Funcionamiento de la unidad
                            </a>
                            <a className="nav-link" id="v-pills-permisionarios-tab" data-toggle="pill" href="#v-pills-permisionarios" role="tab" aria-controls="v-pills-permisionarios" aria-selected="true">
                                <Icon icon="fa-solid fa-file-circle-check fa- mr-2" />
                                Documentos y facturas
                            </a>
                            <a className="nav-link" id="v-pills-economicos-tab" data-toggle="pill" href="#v-pills-economicos" role="tab" aria-controls="v-pills-economicos" aria-selected="true">
                                <Icon icon="fa-solid fa-user mr-2" />
                                Historial permisionarios
                            </a>
                            <a className="nav-link" id="v-pills-unidades-tab" data-toggle="pill" href="#v-pills-unidades" role="tab" aria-controls="v-pills-unidades" aria-selected="true">
                                <Icon icon="fa-solid fa-id-card mr-2" />
                                Historial operadores
                            </a>
                            {
                                permisosUsuario.vehiculo[0].editar == 1 &&
                                    (vehiculo.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-circle-minus mr-2" />
                                        Deshabilitar
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-circle-plus mr-2" />
                                        Habilitar
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                <h3>
                                    Datos del vehículo:
                                </h3>

                                {
                                    Object.keys(vehiculo).length !== 0 && 
                                        <FormularioVehiculo 
                                            vehiculo={vehiculo}
                                            permisosUsuario={permisosUsuario.vehiculo}
                                            onSubmit={onSubmitUpdateVehiculo}
                                        />
    
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-prorrogas" role="tabpanel" aria-labelledby="v-pills-prorrogas-tab">

                                <h3>
                                    Concesiones y rutas:
                                </h3>

                                <p>
                                    Aquí se muestra la concesión, ruta y económico al que está asignado esta unidad.
                                </p>

                                {
                                    (permisosUsuario.vehiculo[0].escribir == 1 && Object.keys(vehiculo).length !== 0) && 
                                    (
                                    <React.Fragment>
                                        <button 
                                            className="btnPrimario btnPrimario-sm" 
                                            onClick={()=>onClickPonerUnidadEnFuncionamiento(permisionarios)}
                                        >
                                            <Icon icon="fas fa-circle-plus mr-2" />
                                            Poner en funcionamiento
                                        </button>
                                        <ModalAsignacionVehiculo
                                            id_vehiculo={id_vehiculo}
                                            concesionesEconomicos={concesionesEconomicos}
                                            operadores={operadores}
                                            getConcesionesEconomicosByVehiculo={getConcesionesEconomicosByVehiculo}
                                            getOperadoresByVehiculo={getOperadoresByVehiculo}
                                        />
                                    </React.Fragment>
                                    )
                                }

                                {
                                    concesionesEconomicos.length >= 1 && 
                                    <React.Fragment>
                                        <hr className='my-3'/>

                                        <Table>
                                            <TableHead rows={rowsTableVehiculosConcesionesEconomicos} />

                                            <TableBodyHistorial elements={concesionesEconomicos} type="concesionesEconomicos" permisosUsuario={permisosUsuario.vehiculo}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-permisionarios" role="tabpanel" aria-labelledby="v-pills-permisionarios-tab">

                                <h3>
                                    Documentos:
                                </h3>

                                {
                                    Object.keys(vehiculo).length !== 0 && 
                                        <FormularioDocumentosVehículo 
                                            vehiculo={vehiculo}
                                            permisosUsuario={permisosUsuario.vehiculo}
                                            rutaServidor={vehiculo.rutaServidor}
                                        />
                                    
                                }
                                
                                <hr></hr>

                                <h3>
                                    Facturas:
                                </h3>
                                <p>
                                    Selecciona la factura que deseas ver:
                                </p>

                                <div className="accordion my-4" id="accordionFacturas">

                                    {
                                        Object.keys(vehiculo).length !== 0 && 
                                        vehiculo.facturas.map((factura) => {
                                            let titulo = "Factura " + factura.id_factura;
                                            if(factura.es_origen === "1") 
                                                titulo = "Factura " + factura.id_factura + " (origen)";
                                            
                                            return (
                                                <CardAccordion
                                                    titulo={titulo}
                                                    show={false}
                                                    id={factura.id_factura}
                                                    idAccordion="accordionFacturas"
                                                    key={factura.id_factura}
                                                >
                                                    <FormularioFacturasVehiculo 
                                                        factura={factura}
                                                        permisosUsuario={permisosUsuario.vehiculo}
                                                        rutaServidor={vehiculo.rutaServidor}
                                                    />
                                                </CardAccordion>
                                            );
                                        })
                                    }

                                </div>
                                

                            </div>

                            <div className="tab-pane fade" id="v-pills-economicos" role="tabpanel" aria-labelledby="v-pills-economicos-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Permisionarios del vehículo:
                                    </h3>

                                    {
                                        (permisosUsuario.vehiculo[0].escribir == 1 && Object.keys(vehiculo).length !== 0 && Object.keys(allPermisionarios).length !== 0) && 
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionPermisionario">
                                                <Icon icon="fas fa-circle-plus mr-2" />
                                                Asignar permisionario
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un permisionario al vehículo con número de serie: ${vehiculo.numero_serie}`}
                                                labelSelect="Seleccione un permisionario:"
                                                dataSelect={allPermisionarios}
                                                dataFiltered={permisionarios}
                                                idSelect="id_permisionario"
                                                idModal="modalAsignacionPermisionario"
                                                onSubmit={onSubmitAsignacionPermisionario}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    permisionarios.length === 0 &&
                                    <p className="text-muted">
                                        No existen permisionarios vinculados al vehículo
                                    </p>
                                }

                                {
                                    permisionarios.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableOperadores} />

                                            <TableBodyHistorial elements={permisionarios} type="permisionarios" permisosUsuario={permisosUsuario.vehiculo}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-unidades" role="tabpanel" aria-labelledby="v-pills-unidades-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Operadores del vehículo:
                                    </h3>

                                    {
                                        (permisosUsuario.vehiculo[0].escribir == 1 && Object.keys(vehiculo).length !== 0) && 
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm"
                                                onClick={()=>onClickAsignarOperador(operadores)}>
                                                <Icon icon="fas fa-circle-plus mr-2" />
                                                Asignar operador
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un operador al vehículo con número de serie: ${vehiculo.numero_serie}`}
                                                labelSelect="Seleccione un operador:"
                                                dataSelect={operadores}
                                                // dataFiltered={operadoresByPermisionario}
                                                idSelect="id_operador"
                                                idModal="modalAsignacionOperador"
                                                onSubmit={onSubmitAsignacionOperador}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    operadoresByPermisionario.length === 0 &&
                                    <p className="text-muted">
                                        No existen operadores vinculados a este permisionario
                                    </p>
                                }

                                {
                                    operadoresByPermisionario.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableOperadores} />
                                            <TableBodyHistorial 
                                                elements={operadoresByPermisionario} 
                                                type="trabajadores" 
                                                permisosUsuario={permisosUsuario.vehiculo}
                                                getReloadElements={getReloadElements}
                                            />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}