import React from 'react'
import { useParams } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import Icon from '../../components/Icon'
import AddButton from '../../components/AddButton'
import $ from 'jquery'
import AlertNoData from '../../components/AlertNoData'
import Table from '../../components/Table'
import TableHead from '../../components/Table/TableHead'
import getSiniestro from '../../services/siniestro/getSiniestro'
import getPresiniestro from '../../services/presiniestro/getPresiniestro'
import ModalAddLesionados from '../../components/ModalAddLesionados'
import TableBody from '../../components/Table/TableBody'
import addLesionadoSiniestro from '../../services/siniestro/addLesionadoSiniestro'
import ModalAddVehiculoAfectado from '../../components/ModalAddVehiculoAfectado'
import addVehiculoAfectadoSiniestro from '../../services/siniestro/addVehiculoAfectadoSiniestro'
import ModalAddBienAfectado from '../../components/ModalAddBienAfectado'
import addBienAfectadoSiniestro from '../../services/siniestro/addBienAfectadoSiniestro'
import ModalAddComentario from '../../components/ModalAddComentario'
import addSeguimientoSiniestro from '../../services/siniestro/addSeguimientoSiniestro'
import ModalAddGastoSiniestro from '../../components/ModalAddGastoSiniestro'
import addGastoSiniestro from '../../services/siniestro/addGastoSiniestro'
import getGastosSiniestro from '../../services/siniestro/getGastosSiniestro'
import CardCorteSemanal from '../../components/CardCorteSemanal'
import ModalCerrarSiniestro from '../../components/ModalCerrarSiniestro'
import getGastosTotalesSiniestro from '../../services/siniestro/getGastosTotalesSiniestro'
import cerrarSiniestro from '../../services/siniestro/cerrarSiniestro'
import Swal from 'sweetalert2'
import cerrarSiniestroInformativo from '../../services/siniestro/cerrarSiniestroInformativo'
import PDFGeneratorSiniestro from '../../components/PDFGeneratorSiniestro'
import ProrrataSiniestro from '../../components/ProrratasSiniestro'

// Componentes para el chat con IA
import { Widget, addResponseMessage } from 'react-chat-widget';
import logo from './../../assets/images/robot.jpg'
import 'react-chat-widget/lib/styles.css';
import updateOpenSiniestro from '../../services/siniestro/updateOpenSiniestro'
import ModalCerrarSiniestroAgain from '../../components/ModalCerrarSiniestroAgain'
import cerrarSiniestroAgain from '../../services/siniestro/cerrarSiniestroAgain'
import ModalImprimirSiniestro from '../../components/ModalImprimirSiniestro'


const { Configuration, OpenAIApi } = require("openai");

export default function ViewSiniestro({permisosUsuario}){

  let { id } = useParams()

  // Loading para indicar cuando cargan los datos
  const [loading, setLoading] = React.useState(true)
  const [loadingGastos, setLoadingGastos] = React.useState(true)
  const [showPDF, setShowPDF] = React.useState(false)
  const [selectedImages, setSelectedImages] = React.useState([]);

  const [siniestro, setSiniestro] = React.useState({})
  const [presiniestro, setPreSiniestro] = React.useState({})

  // Variables para manejar lesionados, daños, gastos y comentarios
  const [lesionados, setLesionados] = React.useState([])
  const [bienesAfectados, setBienesAfectados] = React.useState([])
  const [vehiculosAfectados, setVehiculosAfectados] = React.useState([])
  const [comentarios, setComentarios] = React.useState([])
  const [gastos, setGastos] = React.useState([])
  const [existeGastoPendienteAprobar, setExisteGastoPendienteAprobar] = React.useState(false)

  const [gastosTotales, setGastosTotales] = React.useState({})

  const [isDirectorSiniestro, setIsDirectorSiniestro] = React.useState(false)
  const [isSupervisorSiniestro, setIsSupervisorSiniestro] = React.useState(false)

  // Variables para el chat con IA
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_API_KEY_OPENAI,
  });
  const openai = new OpenAIApi(configuration);
  let roleSystem = {"role": "system", "content": "Eres un asistente inteligente que sólo va a responder las preguntas en base a la información que se te será proporcionada. La información es un reporte de siniestro. Ahí está la respuesta a lo que te preguntarán, pero sí te pregunta por algo que no exista en la información del reporte, debes decir que no tienes información acerca de lo que está solicitando. Tienes que ser corto y conciso con tus respuestas, incluye tablas o formatea el texto si es necesario. No debes responder preguntas que estén fuera del tema del siniestro. Se te irá nutriendo con la información del reporte en los primeros mensajes."}
  const [messagesBot, setMessagesBot] = React.useState([roleSystem]);
  // ////////////////////////////////

  const IdAreaSiniestros = '3' // Este es el tipo de trabajador (Director de siniestros) que puede cerrar un siniestro (esto segun la tabla "tipo_trabajador" de mysql)
  const IdSupervisorSiniestro = '15' // Este es el tipo de trabajador (Supervisor de siniestros) que puede reabrir un siniestro (esto segun la tabla "tipo_trabajador" de mysql)
  const { director_area_empresa_trabajador_seleccionado } = JSON.parse(localStorage.getItem('user'))
  const { tipo_trabajador_seleccionados } = JSON.parse(localStorage.getItem('user'))

  const isUserDirectorSiniestroService = ()=>{
    if(director_area_empresa_trabajador_seleccionado !== undefined){
      if(director_area_empresa_trabajador_seleccionado.some(value => value.id_area_empresa === IdAreaSiniestros)){
        // Este usuario es director de siniestros.
        setIsDirectorSiniestro(true)
      }
    }
  }
  
  const isUserSupervisorSiniestroService = ()=>{
    if(tipo_trabajador_seleccionados !== undefined){
      if(tipo_trabajador_seleccionados.some(value => value.id_tipo_trabajador === IdSupervisorSiniestro)){
        // Este usuario es director de siniestros.
        setIsSupervisorSiniestro(true)
      }
    }
  }

  const getPresiniestroService = (id_pre_siniestro)=>{
    getPresiniestro({values: {id_pre_siniestro: id_pre_siniestro}})
      .then(response => {
        // console.log(response)
        let _response = JSON.parse(response)
        // console.log(_response)
        if(_response.response === 'success'){
          setPreSiniestro(_response.data)
          setLoading(false)
          setLoadingGastos(false)
        }
      })
      .catch(error =>{
        console.log(error)
      })
  }

  const getSiniestroService = ()=>{
    setLoading(true);
    let data = { id_siniestro: id }
    getSiniestro({values:data})
      .then(response => {
        // console.log(response)
        let _response = JSON.parse(response)
        console.log(_response)
        if(_response.response === 'success'){
          setSiniestro(_response.siniestro)
          
          if(_response.siniestro.lesionados !== undefined && _response.siniestro.lesionados !== null){
            setLesionados(_response.siniestro.lesionados)
          }
          if(_response.siniestro.bienes_afectados !== undefined && _response.siniestro.bienes_afectados !== null){
            setBienesAfectados(_response.siniestro.bienes_afectados)
          }
          if(_response.siniestro.vehiculos_afectados !== undefined && _response.siniestro.vehiculos_afectados !== null){
            setVehiculosAfectados(_response.siniestro.vehiculos_afectados)
          }
          if(_response.siniestro.seguimiento !== undefined && _response.siniestro.seguimiento !== null){
            setComentarios(_response.siniestro.seguimiento)
          }
          if(_response.siniestro.gastos !== undefined && _response.siniestro.gastos !== null){
            setGastos(_response.siniestro.gastos)
          }
          getPresiniestroService(_response.siniestro.id_pre_siniestro)
        }
      })
      .catch(error =>{
        console.log(error)
      })
  }

  const getGastosSiniestroService = ()=>{
    let data = { id_siniestro: id}
    setLoadingGastos(true)
    getGastosSiniestro({values:data})
      .then(res =>{
        // console.log(res)
        let _res = JSON.parse(res)
        if(_res.response === 'success'){
          setGastos(_res.data)
          existeGastoPendienteContralor(_res.data);
        }
        setTimeout(()=>{
          setLoadingGastos(false)
        },500)
      })
  }

  const getGastosTotalesSiniestroService = ()=>{
    let data = { id_siniestro: id}
    setLoadingGastos(true)
    getGastosTotalesSiniestro({values:data})
      .then(res =>{
        console.log(res)
        let _res = JSON.parse(res)
        console.log(_res)
        if(_res.response === 'success'){
          setGastosTotales(_res)
          getGastosSiniestroService()
        }
      })
  }

  // Metodos para los Submit de los modales
  const onSubmitModalAddLesionado = (e)=>{
    e.preventDefault()
    document.getElementById('btnSubmitModalAddLesionado').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)
    let nombreGenero = $('#id_genero option:selected').text()

    objectValues.nombre_genero = nombreGenero
    objectValues.id_siniestro = id

    addLesionadoSiniestro({values:objectValues})
      .then(res=>{
        let _res = JSON.parse(res)
        if(_res.response === 'success'){
          objectValues.id_lesionado_siniestro = _res.id_lesionado_siniestro
          setLesionados([...lesionados, objectValues])
          document.getElementById('btnSubmitModalAddLesionado').disabled = false
          $('#modalAddLesionados').modal('hide')
        }
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
      })
      .catch(err=>console.err(err))

  }

  const onSubmitModalAddVehiculoAfectado = (e)=>{
    e.preventDefault()
    document.getElementById('btnSubmitModalAddVehiculoAfectado').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)

    let marcaVehiculo = $('#id_marca_vehiculo option:selected').text()
    if(marcaVehiculo !== 'Elige una marca')
      objectValues.nombre_marca = marcaVehiculo

    let submarcaVehiculo = $('#id_submarca_vehiculo option:selected').text()
    if(submarcaVehiculo !== 'Elige una submarca')
      objectValues.nombre_submarca = submarcaVehiculo

    objectValues.id_siniestro = id

    // console.log(objectValues)

    addVehiculoAfectadoSiniestro({values:objectValues})
      .then(res=>{
        // console.log(res)
        let _res = JSON.parse(res)
        if(_res.response === 'success'){
          objectValues.id_vehiculo_afectado_siniestro = _res.id_vehiculo_afectado_siniestro
          // setLesionados([...lesionados, objectValues])
          setVehiculosAfectados([...vehiculosAfectados, objectValues])
          document.getElementById('btnSubmitModalAddVehiculoAfectado').disabled = false
          $('#modalAddVehiculoAfectado').modal('hide')
        }
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
      })
      .catch(err=>console.err(err))
  }

  const onSubmitModalAddBienAfectado = (e)=>{
    e.preventDefault()

    document.getElementById('btnSubmitModalAddBienAfectado').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)

    let bienAfectado = $('#id_tipo_de_bien option:selected').text()
    if(bienAfectado !== 'Elige una marca')
      objectValues.nombre_tipo_de_bien = bienAfectado

    objectValues.id_siniestro = id
        
    // console.log(objectValues)

    addBienAfectadoSiniestro({values:objectValues})
      .then(res=>{
        // console.log(res)
        let _res = JSON.parse(res)
        if(_res.response === 'success'){
          objectValues.id_vehiculo_afectado_siniestro = _res.id_vehiculo_afectado_siniestro
          // setLesionados([...lesionados, objectValues])
          setBienesAfectados([...bienesAfectados, objectValues])
          document.getElementById('btnSubmitModalAddBienAfectado').disabled = false
          $('#modalAddBienAfectado').modal('hide')
        }
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
      })
      .catch(err=>console.err(err))
  }

  const onSubmitModalAddComentario = (e)=>{
    e.preventDefault()

    document.getElementById('btnSubmitModalAddComentario').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)
        
    objectValues.id_siniestro = id
        
    // console.log(objectValues)

    addSeguimientoSiniestro({values:objectValues})
      .then(res=>{
        // console.log(res)
        let _res = JSON.parse(res)
        if(_res.response === 'success'){
          objectValues.id_seguimiento_siniestro = _res.id_seguimiento_siniestro
          objectValues.fecha_alta = new Date().toISOString()
          setComentarios([...comentarios, objectValues])
          document.getElementById('btnSubmitModalAddComentario').disabled = false
          $('#modalAddComentario').modal('hide')
        }
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
      })
      .catch(err=>console.err(err))
  }

  const onSubmitModalAddGastoSiniestro = (e)=>{
    e.preventDefault()

    document.getElementById('btnSubmitModalAddGastoSiniestro').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)

    let nombreTipoGasto = $('#id_tipo_gasto_siniestro option:selected').text()
    if(nombreTipoGasto !== 'Elige un tipo de gasto')
      objectValues.nombre_tipo_gasto_siniestro = nombreTipoGasto

    let nombreMetodoPago = $('#id_metodo_pago option:selected').text()
    if(nombreMetodoPago !== 'Elige un método de pago')
      objectValues.nombre_metodo_pago = nombreMetodoPago

    let nombreAbonanteSiniestro = $('#id_abonante_siniestro option:selected').text()
    if(nombreAbonanteSiniestro !== 'Elige ...')
      objectValues.nombre_abonante_siniestro = nombreAbonanteSiniestro

    objectValues.id_siniestro = id

    if(objectValues.id_abonante_siniestro === '1'){
      // En caso de que sea empresa
      objectValues.es_aportacion = '0';
    } else {
      // En caso de que sea permisionario o trabajador
      objectValues.es_aportacion = '1';
    }
        
    // console.log(objectValues)

    addGastoSiniestro({values:objectValues})
      .then(res=>{
        // console.log(res)
        let _res = JSON.parse(res)
        if(_res.response === 'success'){

          objectValues.id_gasto_siniestro = _res.id_gasto_siniestro
          objectValues.fecha_alta = new Date().toISOString()

          let _user = JSON.parse(localStorage.getItem('user'))
          objectValues.correo_usuario_alta = _user.correo
          let _gastos = [...gastos];
          _gastos.push(objectValues);
          setGastos([]);
          getGastosSiniestroService()
          getGastosTotalesSiniestroService()
          document.getElementById('btnSubmitModalAddGastoSiniestro').disabled = false
          $('#modalAddGastoSiniestro').modal('hide')
        }
        e.target.reset()
        $('.selectpicker').selectpicker('refresh')
      })
      .catch(err=>console.err(err))
  }

  const onSubmitCerrarSiniestro = (e) =>{
    e.preventDefault()

    document.getElementById('btnSubmitModalCerrarSiniestro').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)
    objectValues.id_siniestro = id

    // console.log(objectValues)

    Swal.fire({
      title:"Estás seguro de cerrar el siniestro?",
      text:"No se podrán deshacer los cambios",
      icon:"info",
      showCancelButton: true,
      confirmButtonText: "Si, estoy seguro",
      cancelButtonText: "Cancelar"
    }).then(value=>{
      if(value.isConfirmed){
        cerrarSiniestro({values:objectValues})
          .then(res=>{
            // console.log(res)
            let _res = JSON.parse(res)
            document.getElementById('btnSubmitModalCerrarSiniestro').disabled = false;
            $('#modalCerrarSiniestro').modal('hide');
            if(_res.response === 'success'){
              Swal.fire({
                title: "Siniestro cerrado",
                timer: 1200,
                icon:'success',
                showConfirmButton:false
              }).then((value)=>{
                if(value.isConfirmed)
                  $('#modalCerrarSiniestro').modal('hide');
              })
              realoadSiniestro()
            } else {
              Swal.fire({
                title: "Error al cerrar el siniestro",
                timer: 1200,
                icon:'error',
                showConfirmButton:false
              }).then((value)=>{
                if(value.isConfirmed)
                  $('#modalCerrarSiniestro').modal('hide');
              })
            }
            
          })
          .catch(err=>console.error(err))
      } else {
        document.getElementById('btnSubmitModalCerrarSiniestro').disabled = false;
      }
    })
  }

  const onSubmitCerrarSiniestroAgain = (e) =>{
    e.preventDefault()

    document.getElementById('btnSubmitModalCerrarSiniestroAgain').disabled = true

    let values = new FormData(e.target)
    let objectValues = Object.fromEntries(values)
    objectValues.id_siniestro = id

    // console.log(objectValues)

    Swal.fire({
      title:"Estás seguro de cerrar el siniestro?",
      text:"No se podrán deshacer los cambios",
      icon:"info",
      showCancelButton: true,
      confirmButtonText: "Si, estoy seguro",
      cancelButtonText: "Cancelar"
    }).then(value=>{
      if(value.isConfirmed){
        cerrarSiniestroAgain({values:objectValues})
          .then(res=>{
            let _res = JSON.parse(res)
            document.getElementById('btnSubmitModalCerrarSiniestroAgain').disabled = false;
            $('#modalCerrarSiniestroAgain').modal('hide');
            if(_res.response === 'success'){
              Swal.fire({
                title: "Siniestro cerrado",
                timer: 1200,
                icon:'success',
                showConfirmButton:false
              }).then((value)=>{
                if(value.isConfirmed)
                  $('#modalCerrarSiniestroAgain').modal('hide');
              })
              realoadSiniestro()
            } else {
              Swal.fire({
                title: "Error al cerrar el siniestro",
                timer: 1200,
                icon:'error',
                showConfirmButton:false
              }).then((value)=>{
                if(value.isConfirmed)
                  $('#modalCerrarSiniestroAgain').modal('hide');
              })
            }
            
          })
          .catch(err=>console.error(err))
      } else {
        document.getElementById('btnSubmitModalCerrarSiniestroAgain').disabled = false;
      }
    })
  }

  const Marcador = () => <i className="fa-solid fa-location-crosshairs" style={{fontSize: '3em', color:'var(--color-rojo)', margin:'-20px'}}></i>

  const existeGastoPendienteContralor = (gastosSiniestro)=>{
    if(gastosSiniestro.length >= 1){
      let existe = gastosSiniestro.some(gasto => {
        if(gasto.es_aportacion !== '1'){
          if(gasto.aprobado === null ){
            return true;
          }

          if(gasto.aprobado === '1' && gasto.gasto_contralor === undefined){
            return true;
          }
          
          if(gasto.gasto_contralor !== undefined && gasto.gasto_contralor.aprobado === null){
            return true;
          }

        }

        return false;
      })
      setExisteGastoPendienteAprobar(existe)
    }
  }

  const onClickMarcarSiniestroInformativo = ()=>{
    Swal.fire({
      title: "Seguro que quieres marcarlo como informativo?",
      text: "Se cerrará el siniestro y generará su póliza",
      icon:"question",
      showCancelButton:true,
      confirmButtonText: "Si, seguro"
    }).then(value =>{
      if(value.isConfirmed){
        let data = {
          id_siniestro: id
        }
        cerrarSiniestroInformativo({values:data})
          .then(res=>{
            // console.log(res)
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
              realoadSiniestro();
            } else {
              Swal.fire({
                title: "Error al marcar como informativo",
                timer:1500,
                showConfirmButton:false
              })
            }
          })
          .catch(err=>console.error(err))
      }
    })
  }

  const realoadSiniestro = ()=>{
    getSiniestroService();
    getGastosTotalesSiniestroService();
  }

  const handleNewUserMessage = async (newMessage) => {
    // Necesitamos agregar este nuevo mensaje a la variable global messagesBot
    let _newMessagesBot = [...messagesBot]
    if(messagesBot.length === 1){
      // let initialData = {"role": "user", "content": `REPORTE DEL SINIESTRO\n Autobus. ID: 17. Poliza: 2023-0001. \nDATOS DE LA POLIZA\n Reportado el: 2023-01-06 11:10:00. Fecha del siniestro: 2023-01-06. Permisionario: Adalberto Matias López. Clave permisionario: 5. Operador: Eduardo Jimenez Operador (REGISTRADO). Fecha de nacimiento operador: 1997-10-30. Licencia: Federal (VIGENTE). Gestor del siniestro: David Prueba Gestor. Estatus siniestro: CERRADO. Unidad: B42069420. Placas: GAGHSD67. Número de serie: 34567890878654342. Aseguradora: HSBC SEGUROS, S.A. de C.V.\n`}
      // _newMessagesBot.push(initialData);

      _newMessagesBot.push({"role": "user", "content": `REPORTE DEL SINIESTRO\n\n ID del siniestro: 17 \n Se generó la póliza: SI, es la póliza 2023-0001. \nDATOS DE LA POLIZA:\n Reportado el: 2023-01-06 11:10:00. Fecha del siniestro: 2023-01-06. Permisionario: Adalberto Matias López. Clave permisionario: 5. Operador: Eduardo Jimenez Operador (REGISTRADO). Fecha de nacimiento operador: 1997-10-30. Licencia: Federal (VIGENTE). Gestor del siniestro: David Prueba Gestor. Estatus siniestro: CERRADO. Número económico: B42069420. Placas: GAGHSD67. Número de serie unidad: 34567890878654342. Tipo de vehículo: Autobus. Aseguradora: HSBC SEGUROS, S.A. de C.V. Prorrata elegida: CARGO A OPERADOR, el 2023-02-17. \n Hubo apoyo de la empresa en la prorrata?: NO \nVEHICULOS AFECTADOS: SI\n Tabla:\nMarca\tModelo\tPlacas\tConductor\tObservación\nVolkswaggen, Jetta\t2004\tEED5R3\tAndrea Garcia\tSin observaciones \nLESIONADOS: SI\n Tabla:\nNombre\tGénero\tObservación\nRicardo Acuna Sandoval\tHombre\ttest \nBIENES AFECTADOS: SI\n Tabla:\nNombre\tObservación\nBIENES FUNGIBLES\tChoque con un poste`});

      _newMessagesBot.push({"role": "user", "content": `Continuación del reporte:\n\n \nUBICACIÓN REPORTADA EN PRE SINIESTRO:\n Av. Jorge Jiménez Cantú 40, Villa de Gpe Xal, 55339 Ecatepec de Morelos, Méx., México \n Persona quien reporto el siniestro: Adalberto Jimenez. \nUBICACIÓN REPORTADA DEL SINIESTRO:\n Av Abedules 10b, Izcalli Sta Clara 1ra Etapa, 55265 Ecatepec de Morelos, Méx., Mexico.\n Ubicación confirmada por el gestor: David Prueba Gestor. \nUBICACIÓN DEL GESTOR AL CONFIRMAR EL SINIESTRO:\n Av Abedules 10b, Izcalli Sta Clara 1ra Etapa, 55265 Ecatepec de Morelos, Méx., Mexico. \nGASTOS DEL SINIESTRO:\n Gastos de empresa: $10,320 aportado: 60.76% \n Gastos de permisionario: $0 aportado: 0% \nGastos de operador: $6,666 aportado: 39.24% \n TOTAL: $16,986. \n APORTACIONES TOTALES: $6,666 (las aportaciones totales son la suma de los gastos del operador y el permisionario) \n DEDUCIBLE APLICANDO LA PRORRATA:\n Empresa: $0 obligado: 0%\n Permisionario: $0 obligado: 0%\n Operador: $16,986 obligado: 100% \nDESCRIPCIÓN DE LO OCURRIDO:\n Esto es un pre siniestro de prueba \n DESCRIPCIÓN DE LO OCURRIDO, ESCRITO POR EL GESTOR:\n Esto es un pre siniestro de prueba`});
      
    }
    let message = { role:"user", content: newMessage };
    _newMessagesBot.push(message);
    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: _newMessagesBot,
    });
    console.log(_newMessagesBot)
    addResponseMessage(completion.data.choices[0].message.content);
    // Agregamos la respuesta del bot al historial de mensajes
    _newMessagesBot.push(completion.data.choices[0].message);
    console.log(_newMessagesBot)
    setMessagesBot(_newMessagesBot);
  };

  const openSiniestro = ()=>{
    Swal.fire({
      title: "Seguro que deseas abrir la póliza del siniestro?",
      text: "Un director de siniestros deberá cerrarlo de nuevo, eligiendo una nueva prorrata.",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, estoy seguro",
      icon: "question"
    }).then(value =>{
      if(value.isConfirmed){
        let data = { id_siniestro: siniestro.id_siniestro }
        updateOpenSiniestro({values:data})
          .then(res => {
            // console.log(res)
            let _res = JSON.parse(res)
            if(_res.response === 'success'){
              Swal.fire({
                title: "Siniestro abierto",
                timer: 1300,
                showConfirmButton: false,
                icon: "success"
              })
              // Volvemos a cargar toda la información
              getSiniestroService()
            } else {
              Swal.fire({
                title: "No se pudo abrir el siniestro",
                text: "Repórtalo al desarrollador",
                icon: "error"
              })
            }
          })
      }
    })
  }

  const toBase64 = async (url) => {
    console.log(url)
    const response = await fetch(url);
    console.log(response)
    const blob = await response.blob();
    console.log(blob)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error al convertir la imagen a base64."));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const onSubmitImprimirSiniestro = async (e) =>{
    e.preventDefault()
    // Debemos obtener las imágenes seleccionadas del usuario
    $("#modalImprimirSiniestro").modal('hide')
    // Swal.showLoading()
    if(selectedImages.length > 0){
      // const base64Images = await Promise.all(selectedImages.map((url) => toBase64(url)))
      // setSelectedImages(base64Images)
    }
    // Swal.hideLoading()
    // Swal.fire({timer:10, showConfirmButton: false})
    setShowPDF(true);
  }

  React.useEffect(getSiniestroService, [])
  React.useEffect(isUserDirectorSiniestroService, [])
  React.useEffect(isUserSupervisorSiniestroService, [])
  // React.useEffect(getGastosTotalesSiniestroService, [])
  React.useEffect(()=>{
    if(!loading)
      getGastosTotalesSiniestroService()
  }, [loading])

  if(!loading){

    return <section>

      <Widget 
        profileAvatar={logo}
        emojis={false}
        title="Haz una pregunta"
        subtitle="Sólo relacionado al siniestro"
        senderPlaceHolder={"Ejemplo: Escribe un resúmen del siniestro"}
        handleNewUserMessage={handleNewUserMessage}
      />

      <input
        action="action"
        onClick={() => {window.history.go(-1); return false}}
        type="submit"
        value="Regresar"
        className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
      />

      <div className="d-flex justify-content-between align-items-bottom">
        <div>
          <h1 className="mt-4">
                        Siniestro ID{id} Eco. {siniestro.clave_prefijo_economico}{siniestro.numero_economico} ({siniestro.modalidad_prefijo_economico})
          </h1>
          <h2 className="title-sm text-muted">
                        Permisionario: {siniestro.clave_permisionario} {siniestro.nombre_permisionario} {siniestro.apellido_paterno_permisionario} {siniestro.apellido_materno_permisionario}
          </h2>
          <p>
            <i className="fa-solid fa-exclamation-circle color-gris mr-2"></i>
                        Estado: {siniestro.nombre_estado_siniestro}
          </p>
          {
            siniestro.numero_poliza !== null &&
              <p className='mt-2'>
                <i className="fa-solid fa-file color-gris mr-2"></i>
                            Póliza: {siniestro.numero_poliza}
              </p>
          }
          {
            siniestro.prorratas !== null && siniestro.prorratas.length === 1 &&
              <p className='mt-2'>
                <i className="fa-solid fa-hand-point-right color-rojo mr-2"></i>
                            Última prorrata aplicada: { siniestro.prorratas[0].nombre }
              </p>
          }
        </div>

        <div className='d-flex flex-column justify-content-end'>

          {/* Botón para volver a abrir un siniestro */}
          {/* Esto solo puede ser hecho por el "Supervisor de siniestros" */}
          {
            permisosUsuario[0].editar === '1' &&
            siniestro.id_estado_siniestro === '2' && !loadingGastos &&
            isSupervisorSiniestro &&
              <button 
                  className="btn btn-sm btn-warning btnAbrirSiniestro mt-3"
                  onClick={openSiniestro}
                >
                  <i className="fa-solid fa-lock-open mr-2"></i>
                              Abrir siniestro
                </button>
          }

          {
            siniestro.id_estado_siniestro === '1' && !loadingGastos &&
            gastos.length > 0 && gastos.some((value) => (value.gasto_contralor !== undefined && value.gasto_contralor.aprobado === '1')) &&
            isDirectorSiniestro &&
              <button 
                className="btn btn-sm btn-danger btnCerrarSiniestro mt-3"
                onClick={()=>{
                  // Validamos sí previamente ha sido cerrado el siniestro, esto lo sabremos porque ya existe al menos 1 prorrata
                  // registrada.
                  (siniestro.prorratas === null)  &&
                    $('#modalCerrarSiniestro').modal('show')

                  if(siniestro.prorratas !== null){
                    // Para cerrar el siniestro que ya ha sido cerrado antes, hay que verificar primero si ya dieron de alta un gasto
                    // nuevo, para tomarlo en cuenta para la prorrata.
                    if(gastos.some(gasto => (new Date(gasto.fecha_alta) > new Date(siniestro.prorratas[0].fecha_alta)))){
                      $('#modalCerrarSiniestroAgain').modal('show')
                    } else {
                      Swal.fire({
                        title: "Agrega un gasto antes de cerrar",
                        text: "Para cerrar el siniestro, debe existir al menos un gasto nuevo",
                        icon:"info"
                      })
                    }
                  }
                }}
              >
                <i className="fa-solid fa-circle-xmark mr-2"></i>
                            Cerrar siniestro
              </button>
          }

          {
            siniestro.id_estado_siniestro === '1' && !loadingGastos &&
            (gastos.length === 0 || !gastos.some((value) => (value.gasto_contralor !== undefined && value.gasto_contralor.aprobado === '1')) ) &&
            isDirectorSiniestro &&
            
              <button 
                className="btn btn-sm btn-warning btnCerrarSiniestro mt-3"
                onClick={onClickMarcarSiniestroInformativo}
              >
                <i className="fa-solid fa-circle-xmark mr-2"></i>
                            Marcar como informativo
              </button>
          }

          {
            !showPDF &&
              <button 
                  className="btn btn-sm btn-light mt-2 d-flex align-items-center justify-content-center text-muted w-100" 
                  onClick={() => {
                    if(siniestro.fotos_siniestro !== undefined && siniestro.fotos_siniestro !== null){
                      // Si existe alguna imagen del siniestro, mostrar el modal para que eliga cuales imagenes aparecerán en el PDF
                      $("#modalImprimirSiniestro").modal('show')
                    } else {
                      // Si no existe ninguna imagen, generar directamente el PDF.
                      setShowPDF(true);
                    }
                  }}
              >
                  <i className="fa-solid fa-print mr-2"></i>
                  Imprimir
              </button>
          }

          <PDFGeneratorSiniestro 
            siniestro={siniestro}
            gastosTotales={gastosTotales}
            gastos={gastos}
            presiniestro={presiniestro}
            showPDF={showPDF}
            setShowPDF={setShowPDF}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />
          
        </div>

      </div>

      <hr/>

      {/* SECCIÓN DE INFORMACION GENERAL DEL SINIESTRO */}
      <CardCorteSemanal
        total_empresa={gastosTotales.total_empresa}
        total_permisionario={gastosTotales.total_permisionario}
        total_operador={gastosTotales.total_operador}
        total_siniestro={gastosTotales.total_siniestro}
        total_aportaciones={gastosTotales.total_aportaciones}

        porcentaje_aportado_empresa={gastosTotales.porcentaje_aportado_empresa}
        porcentaje_obligado_empresa={gastosTotales.porcentaje_obligado_empresa}
        
        porcentaje_aportado_permisionario={gastosTotales.porcentaje_aportado_permisionario}
        porcentaje_obligado_permisionario={gastosTotales.porcentaje_obligado_permisionario}
        
        porcentaje_aportado_operador={gastosTotales.porcentaje_aportado_operador}
        porcentaje_obligado_operador={gastosTotales.porcentaje_obligado_operador}
        
      />

      <div className="row">
        <div className="col-6">

          <div className="card">
            <div className="card-header bg-color-azul">
              <p>
                <a className="btn text-white btn-block" data-toggle="collapse" href="#collapsePresiniestro" role="button" aria-expanded="false" aria-controls="collapsePresiniestro">
                  Ver levantamiento en CAS
                </a>
              </p>
            </div>
            
            <div className="collapse" id="collapsePresiniestro">
              <div className="card card-body">
                <div className="card-contet px-3 py-3">
                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-calendar'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Reportado el:</p>
                      <p>{presiniestro.fecha_siniestro}</p>
                      <p className="text-muted">Alta en sistema: {presiniestro.fecha_alta}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-location-dot'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Ubicación reportada:</p>
                      <p>{presiniestro.direccion_hechos_siniestro}</p>
                      <div className="mapaUbicacionPresiniestro mt-3">
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: 'AIzaSyDSmxYQ1X3h8KhSTXzMfGz_HLK3gH396oo' }}
                          defaultCenter={{
                            lat: Number(presiniestro.latitud),
                            lng: Number(presiniestro.longitud)
                          }}
                          defaultZoom={15}
                          // onClick={onClickMap}
                        >
                          <Marcador
                            lat={Number(presiniestro.latitud)}
                            lng={Number(presiniestro.longitud)}
                            icon={'http://maps.google.com/mapfiles/ms/icons/blue.png'}
                            text="Siniestro"
                          />
                        </GoogleMapReact>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-file-shield'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Aseguradora:</p>
                      <p>{presiniestro.nombre_aseguradora}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-user'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Persona que reporta el siniestro:</p>
                      <p>{presiniestro.nombre_persona_reporta_siniestro}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-scroll'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Descripción del siniestro:</p>
                      <p>{presiniestro.descripcion}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-user'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Operador:</p>
                      <p>
                        {presiniestro.nombre_operador} - &nbsp;
                        {(presiniestro.operador_registrado === '1') && 'REGISTRADO'}
                        {(presiniestro.operador_registrado === '0') && 'NO REGISTRADO'}
                      </p>
                      <p className="text-muted">Fecha nacimiento: {presiniestro.fecha_nacimiento_operador}</p>
                      <div className="d-flex">
                        {
                          (presiniestro.foto_perfil_operador !== null && siniestro.foto_perfil_operador !== undefined) &&
                            <a target="_blank" href={presiniestro.foto_perfil_operador} rel="noreferrer">
                              <img className="imgOperador px-2 py-2" src={presiniestro.foto_perfil_operador} alt="Foto perfil operador registrado"/>
                            </a>
                        }
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-id-card'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Documentación de tránsito:</p>
                      <p>
                        {(siniestro.validez_licencia === '1') && 'LICENCIA VIGENTE'}
                        {(siniestro.validez_licenciA === '0') && 'LICENCIA NO VIGENTE'}
                                            &nbsp; - &nbsp;
                        {siniestro.nombre_tipo_licencia}
                      </p>
                    </div>
                  </div>
                                
                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-users'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Lesionados:</p>
                      {
                        (presiniestro.lesionados !== undefined && presiniestro.lesionados !== null) &&
                          <ul className="ml-3 mt-3">
                            {
                              presiniestro.lesionados.map(element => {
                                return <li>
                                  {element.nombre_completo} - {element.nombre_genero}
                                  <br/>
                                  {element.descripcion !== null && <small className="text-muted">{element.descripcion}</small>}
                                </li>
                              })
                            }
                          </ul>
                      }

                      {
                        (presiniestro.lesionados === null || presiniestro.lesionados === undefined) && <p>Sin lesionados</p>
                      }
                    </div>
                  </div>
                                
                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-car'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Vehículos afectados:</p>
                      {
                        (presiniestro.vehiculos_afectados !== undefined && presiniestro.vehiculos_afectados !== null) &&
                          <ul className="ml-3 mt-3">
                            {
                              presiniestro.vehiculos_afectados.map(element => {
                                return <li>
                                  {element.nombre_conductor}
                                  <br/>
                                              Modelo: {element.modelo},&nbsp;
                                              Placas: {element.placas},&nbsp;
                                  { element.nombre_marca_vehiculo ? element.nombre_marca_vehiculo : 'Sin marca' },&nbsp;
                                  { element.nombre_submarca_vehiculo ? element.nombre_submarca_vehiculo : 'Sin submarca' }
                                  <br/>
                                  {element.descripcion !== null && <small className="text-muted">{element.descripcion}</small>}
                                </li>
                              })
                            }
                          </ul>
                      }

                      {
                        (presiniestro.vehiculos_afectados === null || presiniestro.vehiculos_afectados === undefined) && <p>Sin vehículos afectados</p>
                      }
                                        
                    </div>
                  </div>
                                
                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-house'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Daños a bienes:</p>
                      {
                        (presiniestro.bienes_afectados !== undefined && presiniestro.bienes_afectados !== null) &&
                          <ul className="ml-3 mt-3">
                            {
                              presiniestro.bienes_afectados.map(element => {
                                return <li>
                                  {element.nombre_tipo_de_bien}
                                  <br/>
                                  {element.descripcion !== null && <small className="text-muted">{element.descripcion}</small>}
                                </li>
                              })
                            }
                          </ul>
                      }

                      {
                        (presiniestro.bienes_afectados === null || presiniestro.bienes_afectados === undefined) && <p>Sin bienes afectados</p>
                      }
                    </div>
                  </div>
                                
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="col-6">

          <div className="card shadow">
            <div className="card-header bg-color-verde">
              <p>
                <a className="btn text-white btn-block" data-toggle="collapse" href="#collapseSiniestro" role="button" aria-expanded="false" aria-controls="collapseSiniestro">
                  Ver confirmación de {siniestro.nombre_gestor}
                </a>
              </p>
            </div>
            
            <div className="collapse" id="collapseSiniestro">
              <div className="card card-body">
                <div className="card-contet px-3 py-3">

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-calendar'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Reportado el:</p>
                      <p>{siniestro.fecha_siniestro}</p>
                      <p className="text-muted">Alta en sistema: {siniestro.fecha_alta}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-location-dot'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Ubicación confirmada:</p>
                      <p>{siniestro.direccion_hechos_siniestro}</p>
                      <div className="mapaUbicacionPresiniestro mt-3">
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: 'AIzaSyDSmxYQ1X3h8KhSTXzMfGz_HLK3gH396oo' }}
                          defaultCenter={{
                            lat: Number(siniestro.latitud),
                            lng: Number(siniestro.longitud)
                          }}
                          defaultZoom={15}
                          // onClick={onClickMap}
                        >
                          <Marcador
                            lat={Number(siniestro.latitud)}
                            lng={Number(siniestro.longitud)}
                            icon={'http://maps.google.com/mapfiles/ms/icons/blue.png'}
                            text="Siniestro"
                          />
                        </GoogleMapReact>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-file-shield'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Aseguradora:</p>
                      <p>{siniestro.nombre_aseguradora}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-user'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Persona que reporta el siniestro:</p>
                      <p>{siniestro.nombre_persona_reporta_siniestro}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-scroll'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Descripción del siniestro:</p>
                      <p>{siniestro.descripcion}</p>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-user'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Operador:</p>
                      <p>
                        {siniestro.nombre_operador} - &nbsp;
                        {(siniestro.operador_registrado === '1') && 'REGISTRADO'}
                        {(siniestro.operador_registrado === '0') && 'NO REGISTRADO'}
                      </p>
                      <p className="text-muted">Fecha nacimiento: {siniestro.fecha_nacimiento_operador}</p>
                      <div className="d-flex">
                        {
                          (siniestro.foto_perfil_operador !== null && siniestro.foto_perfil_operador !== undefined) &&
                            <a target="_blank" href={siniestro.foto_perfil_operador} rel="noreferrer">
                              <img className="imgOperador px-2 py-2" src={siniestro.foto_perfil_operador} alt="Foto perfil operador registrado"/>
                            </a>
                        }
                        {
                          (siniestro.url_foto_operador !== null && siniestro.url_foto_operador !== undefined) &&
                            <a target="_blank" href={siniestro.url_foto_operador} rel="noreferrer">
                              <img className="imgOperador px-2 py-2" src={siniestro.url_foto_operador} alt="Foto perfil operador en siniestro"/>
                            </a>
                        }
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-id-card'} />
                    </span>
                    <div className="pl-3">
                      <p className="font-weight-bold">Documentación de tránsito:</p>
                      <p>
                        {(siniestro.validez_licencia === '1') && 'LICENCIA VIGENTE'}
                        {(siniestro.validez_licenciA === '0') && 'LICENCIA NO VIGENTE'}
                                            &nbsp; - &nbsp;
                        {siniestro.nombre_tipo_licencia}
                      </p>
                      {
                        (siniestro.url_foto_licencia_frontal !== null || siniestro.url_foto_licencia_trasera !== null) &&
                          <div className="d-flex">
                            {
                              (siniestro.url_foto_licencia_frontal !== null) && 
                                      <a target="_blank" href={siniestro.url_foto_licencia_frontal} rel="noreferrer">
                                        <img className="imgOperador px-2 py-2" src={siniestro.url_foto_licencia_frontal} alt="Foto licencia frontal"/>
                                      </a>
                            }
                            {
                              (siniestro.url_foto_licencia_trasera !== null) && 
                                      <a target="_blank" href={siniestro.url_foto_licencia_trasera} rel="noreferrer">
                                        <img className="imgOperador px-2 py-2" src={siniestro.url_foto_licencia_trasera} alt="Foto licencia trasera"/>
                                      </a>
                            }
                          </div>

                      }
                    </div>
                  </div>
                                
                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-users'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Lesionados:</p>
                      {
                        (siniestro.lesionados !== undefined && siniestro.lesionados !== null) &&
                          <ul className="ml-3 mt-3">
                            {
                              siniestro.lesionados.map(element => {
                                return <li>
                                  {element.nombre_completo} - {element.nombre_genero}
                                  <br/>
                                  {element.descripcion !== null && <small className="text-muted">{element.descripcion}</small>}
                                </li>
                              })
                            }
                          </ul>
                      }

                      {
                        (siniestro.lesionados === null || siniestro.lesionados === undefined) && <p>Sin lesionados</p>
                      }
                    </div>
                  </div>
                                
                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-car'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Vehículos afectados:</p>
                      {
                        (siniestro.vehiculos_afectados !== undefined && siniestro.vehiculos_afectados !== null) &&
                          <ul className="ml-3 mt-3">
                            {
                              siniestro.vehiculos_afectados.map(element => {
                                return <li>
                                  {element.nombre_conductor}
                                  <br/>
                                              Modelo: {element.modelo},&nbsp;
                                              Placas: {element.placas},&nbsp;
                                  { element.nombre_marca_vehiculo ? element.nombre_marca_vehiculo : 'Sin marca' },&nbsp;
                                  { element.nombre_submarca_vehiculo ? element.nombre_submarca_vehiculo : 'Sin submarca' }
                                  <br/>
                                  {element.descripcion !== null && <small className="text-muted">{element.descripcion}</small>}
                                </li>
                              })
                            }
                          </ul>
                      }

                      {
                        (siniestro.vehiculos_afectados === null || siniestro.vehiculos_afectados === undefined) && <p>Sin vehículos afectados</p>
                      }
                                        
                    </div>
                  </div>
                                
                  <hr></hr>

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-house'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Daños a bienes:</p>
                      {
                        (siniestro.bienes_afectados !== undefined && siniestro.bienes_afectados !== null) &&
                          <ul className="ml-3 mt-3">
                            {
                              siniestro.bienes_afectados.map(element => {
                                return <li>
                                  {element.nombre_tipo_de_bien}
                                  <br/>
                                  {element.descripcion !== null && <small className="text-muted">{element.descripcion}</small>}
                                </li>
                              })
                            }
                          </ul>
                      }

                      {
                        (siniestro.bienes_afectados === null || siniestro.bienes_afectados === undefined) && <p>Sin bienes afectados</p>
                      }
                    </div>
                  </div>

                  {
                    (siniestro.fotos_siniestro !== null && siniestro.fotos_siniestro !== undefined) &&
                      <>
                        <hr></hr>

                        <div className="d-flex">
                          <span className="mt-2">
                            <Icon icon={'fa-solid fa-camera'} />
                          </span>
                          <div className="pl-3 w-100">
                            <p className="font-weight-bold">Fotos del siniestro:</p>
                            <div>
                              {
                                siniestro.fotos_siniestro.map(element =>{
                                  return <a target="_blank" href={element.url_foto} rel="noreferrer">
                                    <img className="imgOperador px-2 py-2" src={element.url_foto} />
                                  </a>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </>
                  }

                  <hr />

                  <div className="d-flex">
                    <span className="mt-2">
                      <Icon icon={'fa-solid fa-location-dot'} />
                    </span>
                    <div className="pl-3 w-100">
                      <p className="font-weight-bold">Ubicación del gestor al confirmar el siniestro:</p>
                      <p>{siniestro.direccion_confirmacion_app}</p>
                      <div className="mapaUbicacionPresiniestro mt-3">
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: 'AIzaSyDSmxYQ1X3h8KhSTXzMfGz_HLK3gH396oo' }}
                          defaultCenter={{
                            lat: Number(siniestro.latitud_confirmacion_app),
                            lng: Number(siniestro.longitud_confirmacion_app)
                          }}
                          defaultZoom={15}
                          // onClick={onClickMap}
                        >
                          <Marcador
                            lat={Number(siniestro.latitud_confirmacion_app)}
                            lng={Number(siniestro.longitud_confirmacion_app)}
                            icon={'http://maps.google.com/mapfiles/ms/icons/blue.png'}
                            text="Siniestro"
                          />
                        </GoogleMapReact>
                      </div>
                    </div>
                  </div>
                                
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* INFORMACIÓN DE LAS PRORRATAS */}
      {
        siniestro.prorratas !== null &&
          <>
            <hr/>
            <ProrrataSiniestro 
              prorratas={siniestro.prorratas}
              gastosTotales={gastosTotales}
            />

            <p className='mt-5 font-weight-bold'>
              PASÓ A UNA ENTIDAD LEGAL?
            </p>
            {
              siniestro.id_entidad_legal_siniestro === null &&
                <p>No, el siniestro no fue a una entidad legal</p>
            }
            {
              siniestro.id_entidad_legal_siniestro !== null &&
                <p>
                  Si, el siniestro fue a la entidad legal:&nbsp;
                  <span className='font-weight-bold'>
                    {siniestro.nombre_entidad_legal_siniestro}
                  </span>
                </p>
            }
          </>
      }
            
      <hr/>

      <div className="row my-5">
        <div className="col-12">

          <div className='d-flex align-items-center mb-5'>
            <h3 className='noMargin'>
                            Gastos
            </h3>
            {
              permisosUsuario[0].escribir === '1' && siniestro.id_estado_siniestro === '1' &&
                            
                            <AddButton text="Agregar gasto" onClick={ () => {
                              $('#modalAddGastoSiniestro').modal('show')
                            } } />
            }
          </div>

          {
            (gastos.length >= 1 && !loadingGastos) &&

                        <Table>
                          <TableHead rows={[
                            { id: 1, name: 'Gasto' },
                            { id: 2, name: 'Cantidad' },
                            { id: 3, name: 'Abonante' },
                            { id: 4, name: 'Descripción' },
                          ]} />

                          <TableBody 
                            elements={gastos}
                            typeElement={'gastos'}
                            permisosUsuario={permisosUsuario}
                            setElements={setGastos}
                            updateElements={getGastosTotalesSiniestroService}
                            idEstadoSiniestro={siniestro.id_estado_siniestro}
                            prorratasSiniestro={siniestro.prorratas}
                          />
                        </Table>
          }

          {
            (gastos.length === 0 && !loadingGastos) && 
                            <AlertNoData
                              textAlert={'No hay gastos agregados'}
                            />
          }

          {
            (loadingGastos) && 
                            <AlertNoData
                              textAlert={'Cargando gastos...'}
                            />
          }

        </div>

      </div>
            
      <hr></hr>

      <div className="row">
        <div className="col-6">

          <div className='d-flex align-items-center mb-5'>
            <h3 className='noMargin'>
                            Lesionados
            </h3>
            {
              permisosUsuario[0].escribir === '1' && siniestro.id_estado_siniestro === '1' &&
                            <AddButton text="Agregar lesionado" onClick={ () => {
                              $('#modalAddLesionados').modal('show')
                            } } />
            }
          </div>

          {
            (lesionados.length >= 1) &&

                        <Table>
                          <TableHead rows={[
                            { id: 1, name: 'Nombre' },
                            { id: 2, name: 'Descripción' },
                          ]} />

                          <TableBody 
                            elements={lesionados}
                            typeElement={'lesionados'}
                            permisosUsuario={permisosUsuario}
                            setElements={setLesionados}
                            idEstadoSiniestro={siniestro.id_estado_siniestro}
                          />
                        </Table>
          }

          {
            (lesionados.length === 0) && 
                            <AlertNoData
                              textAlert={'No hay lesionados'}
                            />
          }

        </div>
        <div className="col-6">

          <div className='d-flex align-items-center mb-5'>
            <h3 className='noMargin'>
                            Daños a bienes
            </h3>
            {
              permisosUsuario[0].escribir === '1' && siniestro.id_estado_siniestro === '1' &&
                            <AddButton text="Agregar bien" onClick={ () => {
                              $('#modalAddBienAfectado').modal('show')
                            } } />
            }
          </div>

          {
            (bienesAfectados.length >= 1) &&

                        <Table>
                          <TableHead rows={[
                            { id: 1, name: 'Nombre' },
                            { id: 2, name: 'Descripción' },
                          ]}
                          />

                          <TableBody 
                            elements={bienesAfectados}
                            typeElement={'bienes_afectados'}
                            permisosUsuario={permisosUsuario}
                            setElements={setBienesAfectados}
                            idEstadoSiniestro={siniestro.id_estado_siniestro}
                          />
                        </Table>
          }

          {
            (bienesAfectados.length === 0) && 
                            <AlertNoData
                              textAlert={'No hay daños a bienes'}
                            />
          }


        </div>
      </div>
            
      <hr></hr>

      <div className="row">
        <div className="col-6">

          <div className='d-flex align-items-center mb-5'>
            <h3 className='noMargin'>
                            Daños a vehículos
            </h3>
            {
              permisosUsuario[0].escribir === '1' && siniestro.id_estado_siniestro === '1' &&
                <AddButton text="Agregar vehículo" onClick={ () => {
                  $('#modalAddVehiculoAfectado').modal('show')
                } } />
            }
          </div>

          {
            (vehiculosAfectados.length >= 1) &&

                        <Table>
                          <TableHead rows={[
                            { id: 1, name: 'Conductor' },
                            { id: 2, name: 'Vehículo' },
                            { id: 3, name: 'Descripción' }
                          ]} 
                          />

                          <TableBody 
                            elements={vehiculosAfectados}
                            typeElement={'vehiculos_afectados'}
                            permisosUsuario={permisosUsuario}
                            setElements={setVehiculosAfectados}
                            idEstadoSiniestro={siniestro.id_estado_siniestro}
                          />
                        </Table>
          }

          {
            (vehiculosAfectados.length === 0) && 
                            <AlertNoData
                              textAlert={'No hay daños a vehículos'}
                            />
          }


        </div>

        <div className="col-6">

          <div className='d-flex align-items-center mb-5'>
            <h3 className='noMargin'>
                            Comentarios
            </h3>
            {
              permisosUsuario[0].escribir === '1' && siniestro.id_estado_siniestro === '1' &&
                            <AddButton text="Agregar comentario" onClick={ () => {
                              $('#modalAddComentario').modal('show')
                            } } />
            }
          </div>
                    
          {
            (comentarios.length >= 1) &&

                        <Table>
                          <TableHead rows={[
                            { id: 1, name: 'Mensaje' },
                            // { id: 2, name: "Fecha" },
                          ]} />

                          <TableBody 
                            elements={comentarios}
                            typeElement={'comentarios'}
                            permisosUsuario={permisosUsuario}
                            setElements={setComentarios}
                            idEstadoSiniestro={siniestro.id_estado_siniestro}
                          />
                        </Table>
          }

          {
            (comentarios.length === 0) && 
                            <AlertNoData
                              textAlert={'No hay comentarios'}
                            />
          }

        </div>
      </div>

      <ModalAddLesionados 
        onSubmit={onSubmitModalAddLesionado}
      />

      <ModalAddVehiculoAfectado 
        onSubmit={onSubmitModalAddVehiculoAfectado}
      />

      <ModalAddBienAfectado
        onSubmit={onSubmitModalAddBienAfectado}
      />

      <ModalAddComentario
        onSubmit={onSubmitModalAddComentario}
      />

      <ModalAddGastoSiniestro
        onSubmit={onSubmitModalAddGastoSiniestro}
        bienesAfectados={bienesAfectados}
        vehiculosAfectados={vehiculosAfectados}
        lesionados={lesionados}
      />

      <ModalCerrarSiniestro 
        gasto_total={gastosTotales.total_siniestro}
        gasto_total_permisionario={gastosTotales.total_permisionario}
        gasto_total_operador={gastosTotales.total_operador}
        gasto_total_empresa={gastosTotales.total_empresa}
        
        porcentaje_aportado_empresa={gastosTotales.porcentaje_aportado_empresa}
        porcentaje_aportado_permisionario={gastosTotales.porcentaje_aportado_permisionario}
        porcentaje_aportado_operador={gastosTotales.porcentaje_aportado_operador}
        polizaAnterior={siniestro.ultima_poliza}

        existe_gasto_siniestro_pendiente={existeGastoPendienteAprobar}
        id_operador={siniestro.id_operador}
        onSubmit={onSubmitCerrarSiniestro}
      />

      <ModalCerrarSiniestroAgain 
        gasto_total={gastosTotales.total_siniestro}
        gasto_total_nueva_prorrata={gastosTotales.total_siniestro_nueva_prorrata}
        gasto_total_permisionario={gastosTotales.total_permisionario_nueva_prorrata}
        gasto_total_operador={gastosTotales.total_operador_nueva_prorrata}
        gasto_total_empresa={gastosTotales.total_empresa_nueva_prorrata}

        porcentaje_aportado_empresa={gastosTotales.porcentaje_aportado_empresa_nueva_prorrata}
        porcentaje_aportado_permisionario={gastosTotales.porcentaje_aportado_permisionario_nueva_prorrata}
        porcentaje_aportado_operador={gastosTotales.porcentaje_aportado_operador_nueva_prorrata}
        // polizaAnterior={siniestro.ultima_poliza}

        existe_gasto_siniestro_pendiente={existeGastoPendienteAprobar}
        id_operador={siniestro.id_operador}
        onSubmit={onSubmitCerrarSiniestroAgain}
      />

      {
        !showPDF &&
          <ModalImprimirSiniestro
            onSubmit={onSubmitImprimirSiniestro}
            siniestro={siniestro}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />

      }


    </section>
  } else {
    return <div className="text-center mt-5">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  }
}