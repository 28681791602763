import React from 'react';
import "./FormularioTrabajador.css"
import $ from 'jquery';
import config from '../../config';
import { validateNoNumbers } from './../../validators/validateNoNumbers';
import changeInformacionTrabajadorService from './../../services/changeInformacionTrabajadorService';
import Swal from 'sweetalert2'
import Icon from '../../components/Icon';
import getAreasEmpresaActivos from '../../services/catalogos/area_empresa/getAreasEmpresaActivos';
const URL_API = config.urlApi;

export default function FormularioTrabajador({ trabajador, permisosUsuario }) {
    const [tiposTrabajadores, setTiposTrabajadores] = React.useState([]);
    const [estadosCiviles, setEstadosCiviles] = React.useState([]);
    const [estados, setEstados] = React.useState([]);
    const [municipios, setMunicipios] = React.useState([]);
    const [generos, setGeneros] = React.useState([]);
    const [religiones, setReligiones] = React.useState([]);
    const [areasEmpresa, setAreasEmpresa] = React.useState([]);

    let {
        apellido_materno,
        apellido_paterno,
        area_asignada,
        calle,
        celular,
        codigo_postal,
        colonia,
        comentarios,
        contacto_emergencia,
        correo,
        curp,
        esposa,
        fecha_ingreso,
        fecha_nacimiento,
        id_estado,
        id_estado_civil,
        id_genero,
        id_municipio,
        id_tipo_trabajador,
        id_trabajador,
        nombre,
        numero_exterior,
        numero_interior,
        numero_seguridad_social,
        referencias,
        rfc,
        telefono,
        tiene_alergias,
        tiene_enfermedad_cronica,
        tiene_familiar_discapacidad,
        tiene_hijos,
        id_creencia_religiosa,
        numero_licencia,
        tipo_trabajador_seleccionados,
        areas_empresa_trabajador_seleccionado,
        director_area_empresa_trabajador_seleccionado
    } = trabajador;

    function getDataForm(){
        $.ajax({
            url: URL_API+'controlador/getDataAltaTrabajador.controlador.php',
            success: function(response){
                let _respuesta = JSON.parse(response)
                if(!(_respuesta.response === 'error')){
                    setTiposTrabajadores(_respuesta.tipos_trabajadores)
                    setEstadosCiviles(_respuesta.estados_civiles)
                    setEstados(_respuesta.estados)
                    setGeneros(_respuesta.generos)
                    setReligiones(_respuesta.creencia_religiosa)
                    setDataFormulario();
                }
            }

        })
    }

    const getAreasEmpresaService = () => {
        getAreasEmpresaActivos()
            .then(res => {
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setAreasEmpresa(_res.data)

                    // Seteamos las areas en las que trabaja el trabajador. Lo hacemos aquī porque debemos eserar
                    // a que tengamos los datos desde el backend para poder elegir las areas que tiene asignadas
                    if(areas_empresa_trabajador_seleccionado){
                        areas_empresa_trabajador_seleccionado.forEach(area_empresa => {
                            $('#area_trabajo option[value="' + area_empresa.id_area_empresa +'"]').prop('selected', true);
                        })
                    }
                    
                    if(director_area_empresa_trabajador_seleccionado){
                        director_area_empresa_trabajador_seleccionado.forEach(area_empresa => {
                            $('#area_director option[value="' + area_empresa.id_area_empresa +'"]').prop('selected', true);
                        })
                    }
                }

                $('.selectpicker').selectpicker('refresh');
            })
            .catch(err => console.log(err))
    }

    function getMunicipios(estado){
        $.ajax({
            data:{estado:estado},
            url: URL_API+'controlador/getMunicipios.controlador.php',
            type:"POST",
            success: function(response){
                let _respuesta = JSON.parse(response)
                if(!(_respuesta.response === 'error')){
                    setMunicipios(_respuesta.municipios)
                    document.getElementById("id_municipio").value = id_municipio;
                    $('.selectpicker').selectpicker('refresh')
                }
            }
        })
    }

    function onChangeEstado(value){
        document.getElementById("id_estado").value = value;
        getMunicipios(value)
    }

    const setDataFormulario = () =>{
        document.getElementById("nombre").value = nombre;
        document.getElementById("apellido_paterno").value = apellido_paterno;
        document.getElementById("apellido_materno").value = apellido_materno;
        document.getElementById("fecha_nacimiento").value = fecha_nacimiento;
        document.getElementById("id_genero").value = id_genero;
        document.getElementById("celular").value = celular;
        document.getElementById("telefono").value = telefono
        document.getElementById("correo").value = correo;
        document.getElementById("curp").value = curp;

        document.getElementById("calle").value = calle;
        document.getElementById("numero_exterior").value = numero_exterior;
        document.getElementById("numero_interior").value = numero_interior;
        document.getElementById("referencias").value = referencias;
        document.getElementById("colonia").value = colonia;
        onChangeEstado(id_estado)
        document.getElementById("codigo_postal").value = codigo_postal;

        document.getElementById("rfc").value = rfc;
        document.getElementById("numero_seguridad_social").value = numero_seguridad_social;
        document.getElementById("fecha_ingreso").value = fecha_ingreso;
        document.getElementById("id_estado_civil").value = id_estado_civil;
        document.getElementById("esposa").value = esposa;
        document.getElementById("contacto_emergencia").value = contacto_emergencia;
        document.getElementById("comentarios").value = comentarios;
        
        // document.getElementById("area_asignada").value = area_asignada;
        document.getElementById("tiene_alergias").value = tiene_alergias;
        document.getElementById("tiene_enfermedad_cronica").value = tiene_enfermedad_cronica;
        document.getElementById("tiene_familiar_discapacidad").value = tiene_familiar_discapacidad;
        document.getElementById("tiene_hijos").value = tiene_hijos;
        document.getElementById("id_creencia_religiosa").value = id_creencia_religiosa;
        document.getElementById("numero_licencia").value = numero_licencia;

        // Asignamos los tipos de trabajador seleccionados para este trabajador
        if(tipo_trabajador_seleccionados){
            tipo_trabajador_seleccionados.forEach(tipo_trabajador => {
                $('#id_tipo_trabajador option[value="' + tipo_trabajador.id_tipo_trabajador +'"]').prop('selected', true);
            })
        } else {
            // Si no existen tipos de trabajador asignados en la bd, asignamos el id_tipo_trabajador que viene
            // asignado por defecto en el id_trabajador. Solo si es diferente de NULL.
            if(id_tipo_trabajador !== null){
                $('#id_tipo_trabajador option[value="' + id_tipo_trabajador +'"]').prop('selected', true);
            }
        }

        // Que verifque si esta seleccionado el tipo trabajador de director, y que haga los cambios
        // correspondientes en el front.
        handleSetTipoDeIdentificacion()

        $('.selectpicker').selectpicker('refresh')

        if(permisosUsuario[0].editar === "0"){
            $("form :input, :input[form]").prop("readonly", true);
            $("#id_tipo_trabajador").prop("disabled", true);
            $("#id_estado_civil").prop("disabled", true);
            $("#id_genero").prop("disabled", true);
            $("#id_municipio").attr("disabled",true)
            $("#id_estado").prop("disabled",true)
        } else {
            $("#id_municipio").attr("disabled",false)
        }

    }

    React.useEffect(() => {
        getDataForm();
        getAreasEmpresaService();
        $('.selectpicker').selectpicker();
    }, []);

    const onSubmitUpdateTrabajador = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_trabajador', id_trabajador)
        let tipoTrabajadoresSeleccionados = $("#id_tipo_trabajador").val();
        let areasEnQueTrabaja = $("#area_trabajo").val();
        let areaEnQueEsDirector = $("#area_director").val();
        values.append("tipoTrabajadoresSeleccionados",JSON.stringify(tipoTrabajadoresSeleccionados));
        values.append("areasEnQueTrabajaSeleccionadas",JSON.stringify(areasEnQueTrabaja));
        values.append("areaEnQueEsDirectorSeleccionadas",JSON.stringify(areaEnQueEsDirector));
        let objectValues = Object.fromEntries(values);
        console.log({objectValues})
        changeInformacionTrabajadorService({values:objectValues})
            .then(response => {
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const handleSetTipoDeIdentificacion = () => {
        let tipoTrabajadoresSeleccionados = $("#id_tipo_trabajador").val();
        // Si seleccionó el tipo trabajador de "Director", mostraremos el input
        // para que seleccione de que área es director.
        if(tipoTrabajadoresSeleccionados.some((val => val === '1'))){
            document.getElementsByClassName('director_select').item(0).classList.remove('hidden')
            $('#area_director').prop('disabled', false)
            $('#area_director').attr('readonly', false)
        } else {
            document.getElementsByClassName('director_select').item(0).classList.add('hidden')
            $('#area_director').prop('disabled', true)
            $('#area_director').attr('readonly', true)
            $('#area_director').val('')
        }
        // Verificar que tipo de trabajador selecciono, y deshabilitar el campo
        // para escribir el area asignada a el cuando sea un 'checador, posturero y
        // tomatiempos'.
        if( tipoTrabajadoresSeleccionados.some((value => (value === '4' || value === '8' || value === '9'))) ){
            document.getElementsByClassName('areas_de_trabajo_select').item(0).classList.add('hidden')
            $('#area_trabajo').prop('disabled', true)
            $('#area_trabajo').attr('readonly', true)
            $('#area_trabajo').val('')
        } else {
            document.getElementsByClassName('areas_de_trabajo_select').item(0).classList.remove('hidden')
            $('#area_trabajo').prop('disabled', false)
            $('#area_trabajo').attr('readonly', false)
        }

        $('.selectpicker').selectpicker('refresh');
    }

    return(
        <form onSubmit={onSubmitUpdateTrabajador}>
            {/* ****************************** */}
            {/* BLOQUE DE LOS DATOS PERSONALES */}
            {/* ****************************** */}
        
            <div className="datosPersonales mb-5">
                <h3>
                    Datos personales
                </h3>

                <div className="datosPersonales__formulario row">
                    <div className="col-4">
                        <label htmlFor="nombre">
                            Nombre(s):
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="nombre"
                            name="nombre"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="apellido_paterno">
                            Apellido paterno:
                        </label>
                        <input 
                            type="text" 
                            className="input" 
                            id="apellido_paterno"
                            name="apellido_paterno"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="apellido_materno">
                            Apellido materno:
                        </label>
                        <input 
                            type="text"
                            className="input" 
                            id="apellido_materno"
                            name="apellido_materno"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="fecha_nacimiento">
                            Fecha nacimiento:
                        </label>
                        <input 
                            type="date"
                            className="input"
                            id="fecha_nacimiento"
                            name="fecha_nacimiento"
                            required
                            />
                    </div>
                    <div className="col-4">
                        <label htmlFor="id_genero">
                            Género:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_genero"
                            name="id_genero"
                            // value={ id_genero }
                            required
                        >
                            {
                                generos && 
                                generos.map( genero => (
                                    <option key={ genero.id_genero } value={ genero.id_genero }>
                                        { genero.nombre }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="celular">
                            Número de celular:
                        </label>
                        <input 
                            type="tel" 
                            placeholder="#########" 
                            className="input"
                            onKeyPress={ validateNoNumbers }
                            maxLength="15"
                            id="celular"
                            name="celular"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="telefono">
                            Número local:
                        </label>
                        <input 
                            type="tel" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            maxLength="15"
                            id="telefono"
                            name="telefono"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="correo">
                            Correo electrónico:
                        </label>
                        <input 
                            type="email" 
                            className="input"
                            id="correo"
                            name="correo"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="curp">
                            CURP:
                        </label>
                        <input 
                            type="text" 
                            className="input"
                            id="curp"
                            name="curp"
                            maxLength="20"
                            required
                        />
                    </div>
                </div>

            </div>
            
            
            {/* ********************************* */}
            {/* BLOQUE DE LOS DATOS DEL DOMICILIO */}
            {/* ********************************* */}
        
            <div className="datosDomicilio my-5">
                <h3>
                    Datos de domicilio
                </h3>

                <div className="datosDomicilio__formulario row">
                    <div className="col-4">
                        <label htmlFor="calle">
                            Calle:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="calle"
                            name="calle"
                            required
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="numero_exterior">
                            No. exterior:
                        </label>
                        <input 
                            type="num" 
                            placeholder="###" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="numero_exterior"
                            name="numero_exterior"
                            required
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="numeroInterior">
                            No. interior:
                        </label>
                        <input 
                            type="num" 
                            placeholder="opcional" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="numero_interior"
                            name="numero_interior"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="referencias">
                            Referencias:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="referencias"
                            name="referencias"
                            required
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="colonia">
                            Colonia:
                        </label>
                        <input 
                            type="text"
                            className="input"
                            id="colonia"
                            name="colonia"
                            required
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="estado">
                            Estado:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_estado"
                            name="id_estado"
                            required
                            data-live-search="true"
                        >   
                            <option value="">Elige un estado</option>
                            {
                                estados.map( (estado) => (
                                    <option key={ estado.id_estado } value={ estado.id_estado } >
                                        { estado.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-2">
                        <label htmlFor="municipio">
                            Municipio:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_municipio"
                            name="id_municipio"
                            required
                            data-live-search="true"
                            disabled
                        >
                            <option value="">
                                Elige un municipio
                            </option>
                            {
                                municipios.map( (municipio) => (
                                    <option key={ municipio.id_municipio } value={ municipio.id_municipio } >
                                        { municipio.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="codigo_postal">
                            Código postal:
                        </label>
                        <input 
                            type="num" 
                            placeholder="#####" 
                            className="input"
                            onKeyPress={ validateNoNumbers }
                            maxLength="10"
                            id="codigo_postal"
                            name="codigo_postal"
                            required
                        />
                    </div>
                </div>

            </div>
            
            
            {/* ************************************* */}
            {/* BLOQUE DE LOS DATOS DE IDENTIFICACIÓN */}
            {/* ************************************* */}
        
            <div className="datosIdentificacion my-5">
                <h3>
                    Datos de identificación
                </h3>

                <div className="datosIdentificacion__formulario row">
                    <div className="col-4">
                        <label htmlFor="id_tipo_trabajador">
                            Tipo de trabajador:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_tipo_trabajador"
                            name="id_tipo_trabajador"
                            multiple
                            onChange={ (e) => handleSetTipoDeIdentificacion() }
                            required
                        >
                            {
                                tiposTrabajadores &&
                                tiposTrabajadores.map( (tipo) => (
                                    <option key={ tipo.id_tipo_trabajador } value={ tipo.id_tipo_trabajador }>
                                        { tipo.nombre }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <div className="areas_de_trabajo_select">
                            <label htmlFor="area_trabajo">
                                En qué áreas trabaja?
                            </label>
                            <select 
                                className="input selectpicker"
                                id="area_trabajo"
                                name="area_trabajo"
                                multiple
                            >
                                {
                                    areasEmpresa &&
                                    areasEmpresa.map( (area) => (
                                        <option key={ area.id_area_empresa } value={ area.id_area_empresa }>
                                            { area.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="director_select hidden">
                            <label htmlFor="area_director">
                                De qué áreas es director?:
                            </label>
                            <select 
                                className="input selectpicker"
                                id="area_director"
                                name="area_director"
                                multiple
                                required
                            >
                                {
                                    areasEmpresa &&
                                    areasEmpresa.map( (area) => (
                                        <option key={ area.id_area_empresa } value={ area.id_area_empresa }>
                                            { area.nombre }
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <hr className='my-2' />
                <div className='row'>
                    {/* <div className="col-6">
                        <label htmlFor="area_asignada">
                            Nombre de su área/proyecto:
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="area_asignada"
                            name="area_asignada"
                        />
                    </div> */}
                    <div className="col-3">
                        <label htmlFor="RFC">
                            RFC:
                        </label>
                        <input 
                            type="text"
                            placeholder="XXXX#####XXX" 
                            className="input"
                            id="rfc"
                            name="rfc"
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="numeroSeguridadSocial">
                            # Seg. social:
                        </label>
                        <input 
                            type="num" 
                            placeholder="Opcional" 
                            className="input" 
                            onKeyPress={ validateNoNumbers }
                            id="numero_seguridad_social"
                            name="numero_seguridad_social"
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="fechaIngreso">
                            Fecha ingreso:
                        </label>
                        <input 
                            type="date"
                            className="input" 
                            id="fecha_ingreso"
                            name="fecha_ingreso"
                            required
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="estadoCivil">
                            Estado civil:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_estado_civil"  
                            name="id_estado_civil"
                        >
                            {
                                estadosCiviles &&
                                estadosCiviles.map( (estadoCivil) => (
                                    <option key={ estadoCivil.id_estado_civil } value={ estadoCivil.id_estado_civil }>
                                        { estadoCivil.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>

                    <div className="col-4">
                        <label htmlFor="id_creencia_religiosa">
                            Religión:
                        </label>
                        <select 
                            className="input selectpicker"
                            id="id_creencia_religiosa"  
                            name="id_creencia_religiosa"
                        >
                            <option value="">
                                Opcional
                            </option>
                            {
                                religiones &&
                                religiones.map( (religion) => (
                                    <option key={ religion.id_creencia_religiosa } value={ religion.id_creencia_religiosa }>
                                        { religion.nombre }
                                    </option>
                                ) )
                            }
                        </select>
                    </div>
                    
                    <div className="col-4">
                        <label htmlFor="numero_licencia">
                            Número de licencia:
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="numero_licencia"
                            name="numero_licencia"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="esposa">
                            Sí tiene esposa, escríbelo aquí:
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="esposa"
                            name="esposa"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="contacto_emergencia">
                            Contacto de emergencia:
                        </label>
                        <input 
                            type="num"
                            onKeyPress={ validateNoNumbers }
                            placeholder="Opcional" 
                            className="input"
                            id="contacto_emergencia"
                            name="contacto_emergencia"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="tiene_alergias">
                            ¿Tiene alergias?
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="tiene_alergias"
                            name="tiene_alergias"
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="tiene_enfermedad_cronica">
                            ¿Tiene alguna enfermedad crónica?
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="tiene_enfermedad_cronica"
                            name="tiene_enfermedad_cronica"
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="tiene_familiar_discapacidad">
                            ¿Tiene familiar con discapacidad física o motriz?
                        </label>
                        <input 
                            type="text"
                            placeholder="Opcional" 
                            className="input"
                            id="tiene_familiar_discapacidad"
                            name="tiene_familiar_discapacidad"
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="tiene_hijos">
                            ¿Tiene hijos?, escribe cuántos:
                        </label>
                        <input 
                            type="num"
                            placeholder="Opcional" 
                            className="input"
                            onKeyPress={ validateNoNumbers }
                            maxLength={2}
                            id="tiene_hijos"
                            name="tiene_hijos"
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="comentarios">
                            Anotaciones:
                        </label>
                        <textarea 
                            rows={5}
                            cols={5}
                            type="text"
                            placeholder="(Opcional) Aquí puedes anotar cualquier detalle o comentario extra sobre el trabajador" 
                            className="input textarea"
                            id="comentarios"
                            name="comentarios"
                        />
                    </div>
                </div>

            </div>

            {
                permisosUsuario[0].editar !== "0" &&
                <React.Fragment>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                        <button className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin">
                            <Icon icon="fa-solid fa-pen-to-square mr-3" />
                            Actualizar información del trabajador
                        </button>
                    </div>
                </React.Fragment>
            }
        </form>
    )
}