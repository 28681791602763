import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import Swal from 'sweetalert2'
import $ from 'jquery';
import FormularioConcesion from '../../components/FormularioConcesion';
import getConcesionService from "./../../services/getConcesionService.js";
import changeInformacionConcesionService from './../../services/changeInformacionConcesionService';
import changeEstatusConcesionService from './../../services/changeEstatusConcesionService';

import ModalAsignacion from '../../components/ModalAsignacion';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBodyHistorial from "../../components/Table/TableBodyHistorial";

import getPermisionariosActivosService from "./../../services/getPermisionariosActivosService.js";
import getPermisionariosByConcesionService from "./../../services/getPermisionariosByConcesionService.js";
import addAsignacionPermisionarioByConcesionService from "../../services/addAsignacionPermisionarioByConcesionService.js";

import getVehiculosActivosService from "./../../services/getVehiculosActivosService.js";
import getVehiculosByConcesionService from "./../../services/getVehiculosByConcesionService.js";
// import addAsignacionVehiculoByConcesionService from "../../services/addAsignacionVehiculoByConcesionService.js";

// import getEconomicosActivosService from "./../../services/getEconomicosActivosService.js";
import getEconomicosByConcesionService from "./../../services/getEconomicosByConcesionService.js";
// import addAsignacionEconomicoByConcesionService from "../../services/addAsignacionEconomicoByConcesionService.js";

export default function ViewConcesion({permisosUsuario}) {

    const location = useLocation();
    const id_concesion = location.pathname.split('/')[2];

    const [concesion, setConcesion] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    const [mensajeVigencia, setMensajeVigencia] = React.useState('');
    const [estatusVigencia, setEstatusVigencia] = React.useState('');

    const getFlagVigencia = (vigencia) =>{
        let fechaVigencia = new Date(vigencia).getTime()
        let fechaActual = new Date().getTime()
        let diferenciaEnDias = ((fechaVigencia - fechaActual)/(1000*60*60*24)).toFixed(0)
        if(diferenciaEnDias <= 0){
            setEstatusVigencia('table__estatus-inactive')
            setMensajeVigencia('Vencida')
            return;
        }
        
        if(diferenciaEnDias <= 30 && diferenciaEnDias >= 7){
            setMensajeVigencia('Próxima a vencer')
            setEstatusVigencia('table__estatus-warning')
            return;
        }
        
        if(diferenciaEnDias <= 7 && diferenciaEnDias >= 1){
            if(diferenciaEnDias > 1)
                setMensajeVigencia('Vence en '+diferenciaEnDias+' días')
            else
                setMensajeVigencia('Vence en '+diferenciaEnDias+' día')
            
            setEstatusVigencia('table__estatus-warning')
            return;
        }

        setMensajeVigencia('Vigente')
    }

    function getConcesion(){
        let data = {
            id_concesion: id_concesion
        }
        getConcesionService({values:data})
            .then(response => {
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    setConcesion(_response.concesion);
                    getFlagVigencia(_response.concesion.vigencia)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.log(error);
                console.log("ha petado")
            })
    }

    function onSubmitUpdateConcesion(e){
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_concesion', id_concesion);
        // let objectValues = Object.fromEntries(values);
        // console.log({objectValues})
        changeInformacionConcesionService({values:values})
            .then(response => {
                let _response = JSON.parse(response);
                if(_response.response === 'success'){
                    Swal.fire({
                        title: 'Exito',
                        text: 'Actualizado correctamente',
                        icon: 'success',
                        timer: 1200,
                        showConfirmButton: false
                    })
                } else {
                    Swal.fire({
                        title: 'Sin actualizar',
                        text: 'No actualizaste ningún dato',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                }
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error en el servidor',
                    text: 'Inténtalo de nuevo más tarde o contacta al desarrollador',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
    }

    const onClickEstatus = (activo) => {

        let msg = (activo) ? 'Para activar la concesión, debes asignarla a un permisionario' : 'Al desactivar la concesión, se desasignará de su permisionario actual';
        
        if(activo){
            Swal.fire({
                title: msg,
                icon: 'info'
            })
        } else {
            let txt = 'Desactivar';
            Swal.fire({
                title: msg,
                showCancelButton: true,
                confirmButtonText: txt,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if(result.isConfirmed){
                    let values = new FormData()
                    values.append('id_concesion', id_concesion)
                    values.append('activo', activo)
                    let objectValues = Object.fromEntries(values);
                    console.log({objectValues})
                    changeEstatusConcesionService({values:objectValues}).then(response => {
                        console.log(response)
                        let _respuesta = JSON.parse(response);
                        if (_respuesta.response === "success") {
                            getConcesion()
                            Swal.fire(
                                'Estatus actualizado',
                                '',
                                'success'
                            )
                        }
                        
                    })
                }
            })
        }
    }

    // FUNCIONES PARA EL HISTORIAL DE PERMISIONARIOS

    const [permisionarios, setPermisionarios] = React.useState([]);
    const [permisionariosByConcesion, setPermisionariosByConcesion] = React.useState([]);

    const rowsTablePermisionarios = [
        { id: 1, name: "Permisionario" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    const getPermisionariosActivos = () =>{
        getPermisionariosActivosService()
            .then(response => {
                // console.log(response)
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){}else{
                    setPermisionarios(_response)
                }
            })
            .catch(error => {
                console.log("ha petado getPermisionarios() de ViewConcesion.js: ", error)
            })
    }

    const getPermisionariosByConcesion = () =>{
        let data = {
            id_concesion: id_concesion
        }
        getPermisionariosByConcesionService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                // console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setPermisionariosByConcesion(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getPermisionariosByConcesion() de ViewConcesion.js: ", error)
            })
    }

    const onSubmitAsignacionPermisionario = (e) => {
        e.preventDefault();
        let values = new FormData(e.target)
        values.append('id_concesion', id_concesion)
        let objectValues = Object.fromEntries(values);
        Swal.fire({
            title: "¿Seguro que deseas asignar el permisionario?",
            text: "De ahora en adelante, este permisionario será el dueño de esta concesion.",
            showCancelButton: true,
            confirmButtonText: "Asignar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                addAsignacionPermisionarioByConcesionService({values:objectValues}).then(response => {
                    console.log(response)
                    let _respuesta = JSON.parse(response);
                    if (_respuesta.response === "success") {
                        getPermisionariosByConcesion();
                        getConcesion();
                        $("#modalAsignacionPermisionario").modal('hide');
                        Swal.fire({
                            title: 'Exito',
                            text: 'Asignado correctamente',
                            icon: 'success',
                            timer: 1200,
                            showConfirmButton: false
                        })
                    }
                    
                })
            }
        })
    }

    // ///////////////////////////////////////////////

    // FUNCIONES PARA EL HISTORIAL DE UNIDADES

    // const [vehiculos, setVehiculos] = React.useState([]);
    const [vehiculosByConcesion, setVehiculosByConcesion] = React.useState([]);

    const rowsTableVehiculos = [
        { id: 1, name: "Unidad" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    // const getVehiculosActivos = () =>{
    //     getVehiculosActivosService()
    //         .then(response => {
    //             // console.log(response)
    //             let _response = JSON.parse(response)
    //             if(_response.response === 'error'){}else{
    //                 setVehiculos(_response)
    //             }
    //             // console.log(_response)
    //         })
    //         .catch(error => {
    //             console.log("ha petado getVehiculos() de ViewConcesion.js: ", error)
    //         })
    // }

    const getVehiculosByConcesion = () =>{
        let data = {
            id_concesion: id_concesion
        }
        getVehiculosByConcesionService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                console.log(`Vehiculos activos:`,_response)
                if(_response.response === 'error'){
                } else {
                    setVehiculosByConcesion(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getVehiculosByConcesion() de ViewConcesion.js: ", error)
            })
    }

    // const onSubmitAsignacionVehiculo = (e) => {
    //     e.preventDefault();
    //     let values = new FormData(e.target)
    //     values.append('id_concesion', id_concesion)
    //     let objectValues = Object.fromEntries(values);
    //     Swal.fire({
    //         title: "¿Seguro que deseas asignar el vehículo?",
    //         text: "De ahora en adelante, este vehículo operará con esta concesión.",
    //         showCancelButton: true,
    //         confirmButtonText: "Asignar",
    //         showLoaderOnConfirm: true,
    //         allowOutsideClick: () => !Swal.isLoading()
    //     }).then((result) => {
    //         if(result.isConfirmed){
    //             addAsignacionVehiculoByConcesionService({values:objectValues}).then(response => {
    //                 // console.log(response)
    //                 let _respuesta = JSON.parse(response);
    //                 if (_respuesta.response === "success") {
    //                     getVehiculosByConcesion();
    //                     $("#modalAsignacionVehiculo").modal('hide');
    //                     Swal.fire({
    //                         title: 'Exito',
    //                         text: 'Asignado correctamente',
    //                         icon: 'success',
    //                         timer: 1200,
    //                         showConfirmButton: false
    //                     })
    //                 }
                    
    //             })
    //         }
    //     })
    // }

    // ///////////////////////////////////////////////

    

    // FUNCIONES PARA EL HISTORIAL DE ECONOMICOS

    // const [economicos, setEconomicos] = React.useState([]);
    const [economicosByConcesion, setEconomicosByConcesion] = React.useState([]);

    const rowsTableEconomicos = [
        { id: 1, name: "Económico" },
        { id: 2, name: "Fecha asignación" },
        { id: 3, name: "Estatus" }
    ];

    // const getEconomicosActivos = () =>{
    //     getEconomicosActivosService()
    //         .then(response => {
    //             // console.log(response)
    //             let _response = JSON.parse(response)
    //             console.log(_response)
    //             if(_response.response === 'error'){}else{
    //                 setEconomicos(_response)
    //             }
    //         })
    //         .catch(error => {
    //             console.log("ha petado getEconomicos() de ViewConcesion.js: ", error)
    //         })
    // }

    const getEconomicosByConcesion = () =>{
        let data = {
            id_concesion: id_concesion
        }
        getEconomicosByConcesionService({values:data})
            .then(response => {
                let _response = JSON.parse(response)
                console.log(_response)
                if(_response.response === 'error'){
                } else {
                    setEconomicosByConcesion(_response)
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("ha petado getEconomicosByConcesion() de ViewConcesion.js: ", error)
            })
    }

    // const onSubmitAsignacionEconomico = (e) => {
    //     e.preventDefault();
    //     let values = new FormData(e.target)
    //     values.append('id_concesion', id_concesion)
    //     let objectValues = Object.fromEntries(values);
    //     Swal.fire({
    //         title: "¿Seguro que deseas asignar el económico?",
    //         text: "De ahora en adelante, esta concesión estará asignada al económico.",
    //         showCancelButton: true,
    //         confirmButtonText: "Asignar",
    //         showLoaderOnConfirm: true,
    //         allowOutsideClick: () => !Swal.isLoading()
    //     }).then((result) => {
    //         if(result.isConfirmed){
    //             addAsignacionEconomicoByConcesionService({values:objectValues}).then(response => {
    //                 console.log(response)
    //                 let _respuesta = JSON.parse(response);
    //                 if (_respuesta.response === "success") {
    //                     getEconomicosByConcesion();
    //                     $("#modalAsignacionEconomico").modal('hide');
    //                     Swal.fire({
    //                         title: 'Exito',
    //                         text: 'Asignado correctamente',
    //                         icon: 'success',
    //                         timer: 1200,
    //                         showConfirmButton: false
    //                     })
    //                 }
                    
    //             })
    //         }
    //     })
    // }

    // ///////////////////////////////////////////////


    React.useEffect(() => {
        getConcesion()

        getPermisionariosActivos()
        getPermisionariosByConcesion()

        // getVehiculosActivos()
        getVehiculosByConcesion()

        // getEconomicosActivos()
        getEconomicosByConcesion()
    } , []);

    if(!loading){
        return(
            <section>
                <input
                    action="action"
                    onClick={() => {window.history.go(-1); return false;}}
                    type="submit"
                    value="Regresar"
                    className="btnPrimario btnPrimario-sm btnPrimario-colorSecundario noMargin"
                />
    
                <h1 className="my-4">Información de la concesión:</h1>
    
                <hr></hr>
    
                <div className="row">
                    <div className="col-3 text-center">
    
                        <h4 className="my-2">
                            Clave:
                            {
                                concesion.clave
                            }
                        </h4>
    
                        <div className="d-flex flex-column align-items-center">
                            <span className={`flag ${ concesion.activo == 1 ? '' : 'flag-inactive' }`}>
                                { concesion.activo == 1 ? 'Activo' : 'Inactivo' }
                            </span>
                            <p className="mt-2">
                                <small>
                                    Alta en sistema: {concesion.fecha}
                                </small>
                            </p>
                            <p className="mt-2">
                                <small>
                                    Vigencia: {concesion.vigencia_con_formato}
                                </small>
                            </p>
                            <div className="table__estatus justify-content-center mt-2">
                                <span className={ estatusVigencia }>
                                    {mensajeVigencia}
                                </span>
                            </div>
                        </div>

    
                        <hr></hr>
    
                        <div className="my-2 text-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <Icon icon="fa-solid fa-file-invoice mr-2" />
                                Concesión
                            </a>
                            <a className="nav-link" id="v-pills-permisionarios-tab" data-toggle="pill" href="#v-pills-permisionarios" role="tab" aria-controls="v-pills-permisionarios" aria-selected="true">
                                <Icon icon="fa-solid fa-user mr-2" />
                                Historial permisionarios
                            </a>
                            <a className="nav-link" id="v-pills-unidades-tab" data-toggle="pill" href="#v-pills-unidades" role="tab" aria-controls="v-pills-unidades" aria-selected="true">
                                <Icon icon="fa-solid fa-truck-moving mr-2" />
                                Historial unidades
                            </a>
                            <a className="nav-link" id="v-pills-economicos-tab" data-toggle="pill" href="#v-pills-economicos" role="tab" aria-controls="v-pills-economicos" aria-selected="true">
                                <Icon icon="fa-solid fa-route mr-2" />
                                Historial económicos
                            </a>
                            {/* <a className="nav-link" id="v-pills-prorrogas-tab" data-toggle="pill" href="#v-pills-prorrogas" role="tab" aria-controls="v-pills-prorrogas" aria-selected="true">
                                <Icon icon="fa-solid fa-hourglass mr-2" />
                                Prórrogas
                            </a> */}
                            {
                                permisosUsuario.concesion[0].editar == 1 &&
                                    (concesion.activo == 1 ?
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(false)}>
                                        <Icon icon="fa-solid fa-circle-minus mr-2" />
                                        Deshabilitar
                                    </a>
                                    :
                                    <a className="nav-link" role="tab" type="button" onClick={() => onClickEstatus(true)}>
                                        <Icon icon="fa-solid fa-circle-plus mr-2" />
                                        Habilitar
                                    </a>)
                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                <h3>
                                    Datos de la concesión:
                                </h3>

                                {
                                    Object.keys(concesion).length !== 0 && 
                                        <FormularioConcesion 
                                            concesion={concesion}
                                            permisosUsuario={permisosUsuario.concesion}
                                            onSubmit={onSubmitUpdateConcesion}
                                        />
    
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-permisionarios" role="tabpanel" aria-labelledby="v-pills-permisionarios-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Permisionarios que han tenido la concesión:
                                    </h3>

                                    {
                                        permisosUsuario.concesion[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionPermisionario">
                                                <Icon icon="fas fa-user-plus mr-2" />
                                                Asignar permisionario
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un permisionario a la concesión: ${concesion.clave}`}
                                                labelSelect="Seleccione un permisionario"
                                                dataSelect={permisionarios}
                                                dataFiltered={permisionariosByConcesion}
                                                idSelect="id_permisionario"
                                                idModal="modalAsignacionPermisionario"
                                                onSubmit={onSubmitAsignacionPermisionario}
                                            />
                                        </React.Fragment>
                                        )
                                    }
                                </div>

                                {
                                    permisionariosByConcesion.length == 0 &&
                                    <p className="text-muted">
                                        No existen permisionarios asignados a esta concesión.
                                    </p>
                                }

                                {
                                    permisionariosByConcesion.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTablePermisionarios} />

                                            <TableBodyHistorial elements={permisionariosByConcesion} type="permisionarios" permisosUsuario={permisosUsuario.concesion}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-unidades" role="tabpanel" aria-labelledby="v-pills-unidades-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Vehículos que han tenido la concesión:
                                    </h3>

                                    {/* {
                                        permisosUsuario.concesion[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionVehiculo">
                                                <Icon icon="fas fa-plus-circle mr-2" />
                                                Asignar vehículo
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un vehiculo a la concesión: ${concesion.clave}`}
                                                labelSelect="Seleccione un vehiculo"
                                                dataSelect={vehiculos}
                                                dataFiltered={vehiculosByConcesion}
                                                idSelect="id_vehiculo"
                                                idModal="modalAsignacionVehiculo"
                                                onSubmit={onSubmitAsignacionVehiculo}
                                            />
                                        </React.Fragment>
                                        )
                                    } */}
                                </div>

                                {
                                    vehiculosByConcesion.length == 0 &&
                                    <p className="text-muted">
                                        No existen vehículos asignados a esta concesión.
                                    </p>
                                }

                                {
                                    vehiculosByConcesion.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableVehiculos} />

                                            <TableBodyHistorial elements={vehiculosByConcesion} type="vehiculos" permisosUsuario={permisosUsuario.concesion}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="tab-pane fade" id="v-pills-economicos" role="tabpanel" aria-labelledby="v-pills-economicos-tab">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>
                                        Económicos que se han asignado a la concesión:
                                    </h3>

                                    {/* {
                                        permisosUsuario.concesion[0].escribir == 1 &&
                                        (
                                        <React.Fragment>
                                            <button className="btnPrimario btnPrimario-sm" data-toggle="modal" data-target="#modalAsignacionEconomico">
                                                <Icon icon="fas fa-plus-circle mr-2" />
                                                Asignar económico
                                            </button>
                                            <ModalAsignacion
                                                titulo={`Asignar un económico a la concesión: ${concesion.clave}`}
                                                labelSelect="Seleccione un economico"
                                                dataSelect={economicos}
                                                dataFiltered={economicosByConcesion}
                                                idSelect="id_economico"
                                                idModal="modalAsignacionEconomico"
                                                onSubmit={onSubmitAsignacionEconomico}
                                            />
                                        </React.Fragment>
                                        )
                                    } */}
                                </div>

                                {
                                    economicosByConcesion.length == 0 &&
                                    <p className="text-muted">
                                        No existen vehículos asignados a esta concesión.
                                    </p>
                                }

                                {
                                    economicosByConcesion.length >= 1 && 
                                    <React.Fragment>
                                        <Table>
                                            <TableHead rows={rowsTableEconomicos} />

                                            <TableBodyHistorial elements={economicosByConcesion} type="economicos" permisosUsuario={permisosUsuario.concesion}  />
                                        </Table>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="tab-pane fade" id="v-pills-prorrogas" role="tabpanel" aria-labelledby="v-pills-prorrogas-tab">

                                <h3>
                                    Historial de prórrogas:
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    } else {
        return(
            <div className="text-center mt-5">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

}