import React from "react";
import $ from 'jquery'
import getProrratasActive from "../../services/catalogos/prorrata/getProrratasActive";
import getReglaProrrataActive from "../../services/catalogos/reglas_prorrata/getReglaProrrataActive";
import getUMAActual from "../../services/catalogos/uma/getUMAActual";
import { useHistory } from "react-router-dom";
import { calculateAfterProrrata } from "../../validators/calculateAfterProrrata";
import getEntidadesLegalesActive from "../../services/catalogos/entidad_legal_siniestro/getEntidadesLegalesActive";

export default function ModalCerrarSiniestroAgain(props){

    let dollarUSLocale = Intl.NumberFormat('en-US');
    let history = useHistory();
    let { 
        gasto_total, 
        gasto_total_nueva_prorrata,
        existe_gasto_siniestro_pendiente, 
        gasto_total_permisionario,
        gasto_total_operador,
        gasto_total_empresa,
        porcentaje_aportado_empresa,
        porcentaje_aportado_permisionario,
        porcentaje_aportado_operador, 
        id_operador
    } = props;

    const [prorratas, setProrratas] = React.useState([]);
    const [reglaProrrateo, setReglaProrrateo] = React.useState(null);
    const [UMA, setUMA] = React.useState(null);
    const [loading, setLoading] = React.useState(true)
    const [prorrataSelected, setProrrataSelected] = React.useState(null);
    const [entidadesLegales, setEntidadesLegales] = React.useState([])
    const [isEntidadLegal, setIsEntidadLegalSelected] = React.useState(null)

    const getEntidadesLegalesService = ()=>{
        setLoading(true)
        getEntidadesLegalesActive()
            .then(res =>{
                let _res = JSON.parse(res)
                _res.response === 'success' && setEntidadesLegales(_res.data)
                $('.selectpicker').selectpicker('refresh')
                setLoading(false)
            })
            .catch(err => { console.log({err}) })
    }

    const getProrratasActiveService = ()=>{
        setLoading(true)
        getProrratasActive()
            .then(res =>{
                let _res = JSON.parse(res)
                _res.response === 'success' && setProrratas(_res.data)
                $('.selectpicker').selectpicker('refresh')
                setLoading(false)
            })
            .catch(err => { console.log({err}) })
    }
        
    const getReglaProrrataActiveService = ()=>{
        setLoading(true)
        getReglaProrrataActive()
            .then(res =>{
                let _res = JSON.parse(res)
                _res.response === 'success' && setReglaProrrateo(_res.data)
                $('.selectpicker').selectpicker('refresh')
                setLoading(false)
            })
            .catch(err => { console.log({err}) })
    }
        
    const getUMAActualService = ()=>{
        setLoading(true)
        getUMAActual()
            .then(res =>{
                let _res = JSON.parse(res)
                _res.response === 'success' && setUMA(_res.data)
                $('.selectpicker').selectpicker('refresh')
                setLoading(false)
            })
            .catch(err => { console.log({err}) })
    }

    const calculateSaldoAfterProrrata = (gasto_total, gasto_after_prorrata)=>{
        let calculo = parseFloat(gasto_total) - parseFloat(gasto_after_prorrata);
        let esPositivo = (calculo === 0) ? null : (calculo > 0 ? true : false);

        return <strong className={`${esPositivo === null ? 'color-gris' : (esPositivo ? 'color-verde' : 'color-rojo')}`}>
            {` $ ${dollarUSLocale.format(calculo)}`}
        </strong>
        
    }

    const validateOperadorIsNull = (prorrata)=>{
        // Si un id_operador es NULL en el siniestro, significa que no se puede elegir una prorrata que 
        // tenga un porcentaje asignado a un operador.
        if(id_operador === null){
            if(prorrata !== null && prorrata.porcentaje_operador > 0)
                return true;
        }

        return false;
    }

    React.useEffect(()=>$(".selectpicker").selectpicker('refresh'),[])
    React.useEffect(getProrratasActiveService,[])
    React.useEffect(getReglaProrrataActiveService,[])
    React.useEffect(getUMAActualService,[])
    React.useEffect(getEntidadesLegalesService,[])

    return(
        <div className="modal" id="modalCerrarSiniestroAgain" tabIndex="-1" role="dialog" aria-labelledby="modalCerrarSiniestroAgainLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form onSubmit={props.onSubmit} id='formCerrarSiniestroAgain' className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalCerrarSiniestroAgainLabel">
                            Cerrar siniestro
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            existe_gasto_siniestro_pendiente !== undefined && existe_gasto_siniestro_pendiente &&
                                <div className="alert alert-danger" role="alert">
                                    Existen gastos pendientes de revisar, revísalos o elimínalos para poder continuar.
                                </div>
                        }
                        <div className="row">
                            <div className="col-12">

                                {
                                    !loading && UMA !== null &&
                                        <p>
                                            Valor 1 UMA año actual: 
                                            <span className="font-weight-bold">
                                                {` $ ${ dollarUSLocale.format(UMA.valor) }`}
                                            </span>
                                        </p>
                                }
                                
                                {
                                    !loading && UMA === null &&
                                    <>
                                        <p>
                                            No se ha dado de alta el valor por UMA del año actual.
                                        </p>
                                        <button 
                                            type="button" 
                                            className="btn btn-sm btn-naranja mt-2"
                                            onClick={()=>{
                                                $("#modalCerrarSiniestroAgain").modal('hide');
                                                history.push('/catalogos/uma');
                                            }}
                                        >
                                            Agregar valor
                                        </button>
                                        <hr className="my-4" />
                                    </>
                                }

                                {
                                    !loading && UMA !== null &&
                                    <>
                                        <p>
                                            Total gastos del siniestro: 
                                            <span className="font-weight-bold color-verde">
                                                {` $ ${dollarUSLocale.format(gasto_total)}`}
                                            </span>
                                            {' -> '} 
                                            <span className="font-weight-bold underline">
                                                {
                                                    dollarUSLocale.format((parseFloat(gasto_total) / parseFloat(UMA.valor)).toFixed(2))
                                                }
                                                &nbsp;UMA's
                                            </span>
                                        </p>
                                        <p>
                                            Nuevos gastos:
                                            <span className="font-weight-bold color-verde">
                                                {` $ ${dollarUSLocale.format(gasto_total_nueva_prorrata)}`}
                                            </span>
                                            {' -> '} 
                                            <span className="font-weight-bold underline">
                                                {
                                                    dollarUSLocale.format((parseFloat(gasto_total_nueva_prorrata) / parseFloat(UMA.valor)).toFixed(2))
                                                }
                                                &nbsp;UMA's
                                            </span>
                                        </p>
                                    </>
                                }

                                <hr className="my-4" />

                                {
                                    !loading && reglaProrrateo !== null &&
                                    <>
                                        <p>
                                            Parámetros actuales para elegir una prorrata:
                                        </p>
                                        <ul className="listaReglasProrrateo">
                                            <li>
                                                De 
                                                <span className="font-weight-bold"> 0 </span>
                                                a 
                                                <span className="font-weight-bold color-azul">{` ${reglaProrrateo.minimo_uma} `}</span> 
                                                UMAs lo paga el permisionario.
                                            </li>
                                            <li>
                                                De 
                                                <span className="font-weight-bold color-azul">{` ${reglaProrrateo.minimo_uma} `}</span> 
                                                a 
                                                <span className="font-weight-bold color-rojo">{` ${dollarUSLocale.format(reglaProrrateo.maximo_uma)} `}</span> 
                                                UMAs lo paga la empresa.
                                            </li>
                                            <li>
                                                Más de 
                                                <span className="font-weight-bold color-rojo">{` ${dollarUSLocale.format(reglaProrrateo.maximo_uma)} `}</span>
                                                UMAs lo paga el permisionario.
                                            </li>
                                        </ul>
                                    </>
                                }
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="id_prorrata">
                                    Prorrata:
                                </label>
                                <select 
                                    className="input selectpicker"
                                    id="id_prorrata"
                                    name="id_prorrata"
                                    data-live-search="true"
                                    onChange={(e) =>{
                                        let id = e.target.value;
                                        let prorrata = prorratas.filter(prorrata => prorrata.id_prorrata === id)
                                        setProrrataSelected(prorrata[0]);
                                    }}
                                    required
                                >
                                    <option value=''>
                                        Selecciona una prorrata
                                    </option>
                                    {
                                        prorratas && 
                                        prorratas.map( prorrata => (
                                            <option key={ prorrata.id_prorrata } value={ prorrata.id_prorrata }>
                                                { prorrata.nombre } |
                                                Permisionario: { prorrata.porcentaje_permisionario }% |
                                                Empresa: { prorrata.porcentaje_empresa }% |
                                                Operador: { prorrata.porcentaje_operador }%
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            {
                                validateOperadorIsNull(prorrataSelected) &&
                                    <div className="alert alert-danger" role="alert">
                                        No puedes elegir una prorrata con deducible al operador, ya que no está registrado.
                                    </div>
                            }
                            {
                                prorrataSelected !== null &&
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">

                                            <p>Permisionario:</p>
                                            <strong>
                                                {` $ ${dollarUSLocale.format(gasto_total_permisionario)}`}
                                                {` (${dollarUSLocale.format(porcentaje_aportado_permisionario)} %) `}
                                            </strong>
                                            {` -> `}
                                            <strong>
                                                {` $ ${dollarUSLocale.format(
                                                        calculateAfterProrrata(gasto_total_nueva_prorrata,prorrataSelected.porcentaje_permisionario))}`}
                                                {` (${prorrataSelected.porcentaje_permisionario} %) `}
                                            </strong>
                                            {` = `}
                                            { 
                                                calculateSaldoAfterProrrata(
                                                    gasto_total_permisionario, 
                                                    calculateAfterProrrata(gasto_total_nueva_prorrata,prorrataSelected.porcentaje_permisionario)
                                                )
                                            }
                                            
                                            <hr className='my-1'/>
                                            
                                            <p>Empresa:</p>
                                            <strong>
                                                {` $ ${dollarUSLocale.format(gasto_total_empresa)}`}
                                                {` (${dollarUSLocale.format(porcentaje_aportado_empresa)} %) `}
                                            </strong>
                                            {` -> `}
                                            <strong>
                                                {` $ ${dollarUSLocale.format(
                                                        calculateAfterProrrata(gasto_total_nueva_prorrata,prorrataSelected.porcentaje_empresa))}`}
                                                {` (${prorrataSelected.porcentaje_empresa} %) `}
                                            </strong>
                                            {` = `}
                                            { 
                                                calculateSaldoAfterProrrata(
                                                    gasto_total_empresa, 
                                                    calculateAfterProrrata(gasto_total_nueva_prorrata,prorrataSelected.porcentaje_empresa)
                                                )
                                            }
                                            
                                            <hr className='my-1'/>
                                            
                                            <p>Operador:</p>
                                            <strong>
                                                {` $ ${dollarUSLocale.format(gasto_total_operador)}`}
                                                {` (${dollarUSLocale.format(porcentaje_aportado_operador)} %) `}
                                            </strong>
                                            {` -> `}
                                            <strong>
                                                {` $ ${dollarUSLocale.format(
                                                        calculateAfterProrrata(gasto_total_nueva_prorrata,prorrataSelected.porcentaje_operador))}`}
                                                {` (${prorrataSelected.porcentaje_operador} %) `}
                                            </strong>
                                            {` = `}
                                            { 
                                                calculateSaldoAfterProrrata(
                                                    gasto_total_operador, 
                                                    calculateAfterProrrata(gasto_total_nueva_prorrata,prorrataSelected.porcentaje_operador)
                                                )
                                            }

                                        </div>
                                    </div>
                            }

                            <div className="col-12">
                                <label htmlFor="descripcion">
                                    Descripción:
                                </label>
                                <textarea 
                                    type="text" 
                                    className="input"
                                    id="descripcion"
                                    name="descripcion"
                                    placeholder="Añade información que describa porque cerraste el siniestro y elegiste esa prorrata"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            data-dismiss="modal"
                        >
                            Cancelar
                        </button>

                        <button 
                            type="submit" 
                            className="btn btn-danger" 
                            id="btnSubmitModalCerrarSiniestroAgain"
                            disabled={ !loading && ( UMA === null || existe_gasto_siniestro_pendiente || validateOperadorIsNull(prorrataSelected) ) && true}
                        >
                                Cerrar siniestro
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}